import React, { useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Input,
  Tfoot,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Box,
  Checkbox,
} from "@chakra-ui/react";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  SettingsIcon,
} from "@chakra-ui/icons";

import ConfigInstance from "./ConfigInstance";

import config from "../../../utils/data/config.json";

function Instance({
  ifcData,
  composing,
  updateComposingInputs,
  categoryIfcTypeExist,
  content,
  i,
  category,
  j,
  instances,
}) {
  const [formOpen, setFormOpen] = useState("");
  const [instanceInput, setInstanceInput] = useState("");
  const [instanceSettingsForm, setInstanceSettingsForm] = useState({
    isOpen: false,
    content: "",
    category: "",
    instance: "",
    settingsType: "",
  });

  // Add / Rename / Remove Instances functions
  function onAddInstanceAction(content, category, instance) {
    const newcomposing = { ...composing };
    newcomposing[content][category]["instances"][instance] = {
      unit: "un",
      group: "Manual",
      parameter: "",
      discretize: {
        group: "-",
        parameter: "",
      },
      subdivide: {
        group: "-",
        parameter: "",
      },
    };
    updateComposingInputs("composing", newcomposing);
  }

  function onRenameInstanceAction(content, category, instance, new_instance) {
    const newcomposing = { ...composing };
    newcomposing[content][category]["instances"][new_instance] =
      newcomposing[content][category]["instances"][instance];
    delete newcomposing[content][category]["instances"][instance];
    updateComposingInputs("composing", newcomposing);
  }

  function onRemoveInstanceAction(content, category, instance) {
    const newcomposing = { ...composing };
    delete newcomposing[content][category]["instances"][instance];
    updateComposingInputs("composing", newcomposing);
  }

  // Change Instance configs functions
  function onChangeInstanceUnit(content, category, instance, unit) {
    const newcomposing = { ...composing };
    newcomposing[content][category]["instances"][instance]["unit"] = unit;
    updateComposingInputs("composing", newcomposing);
  }

  function onChangeInstanceGroup(content, category, instance, group) {
    const newcomposing = { ...composing };
    newcomposing[content][category]["instances"][instance]["group"] = group;
    if (group !== "Manual" && group !== "Quantidade") {
      newcomposing[content][category]["instances"][instance]["parameter"] =
        Object.keys(
          ifcData[composing[content][category]["ifcType"]][0][group]
        )[0];
    }
    updateComposingInputs("composing", newcomposing);
  }

  function onChangeInstanceParameter(content, category, instance, parameter) {
    const newcomposing = { ...composing };
    newcomposing[content][category]["instances"][instance]["parameter"] =
      parameter;
    updateComposingInputs("composing", newcomposing);
  }

  function onChangeInstanceDiscretizer(
    content,
    category,
    instance,
    discretize
  ) {
    if (discretize["group"] !== "-") {
      const newcomposing = { ...composing };
      newcomposing[content][category]["instances"][instance]["discretize"] = {
        group: "-",
        parameter: "",
      };
      updateComposingInputs("composing", newcomposing);
    } else {
      const newcomposing = { ...composing };
      newcomposing[content][category]["instances"][instance]["discretize"] = {
        group: "Material",
        parameter: "",
      };
      updateComposingInputs("composing", newcomposing);
    }
  }

  function onChangeInstanceSubdivisor(content, category, instance, subdivide) {
    if (subdivide["group"] !== "-") {
      const newcomposing = { ...composing };
      newcomposing[content][category]["instances"][instance]["subdivide"] = {
        group: "-",
        parameter: "",
      };
      updateComposingInputs("composing", newcomposing);
    } else {
      const newcomposing = { ...composing };
      newcomposing[content][category]["instances"][instance]["subdivide"] = {
        group: "Material",
        parameter: "",
      };
      updateComposingInputs("composing", newcomposing);
    }
  }

  // Verify if Instance configs exists in ifc functions
  function instanceGroupExist(content, category, group) {
    const exist = group in ifcData[composing[content][category]["ifcType"]][0];
    return exist;
  }

  function instanceParameterExist(content, category, group, parameter) {
    const exist =
      parameter in ifcData[composing[content][category]["ifcType"]][0][group];
    return exist;
  }

  // Open Instance configs form functions
  function openInstanceSettingsForm(content, category, instance, settingsType) {
    setInstanceSettingsForm((prevInputs) => ({
      ...prevInputs,
      ["content"]: content,
    }));
    setInstanceSettingsForm((prevInputs) => ({
      ...prevInputs,
      ["category"]: category,
    }));
    setInstanceSettingsForm((prevInputs) => ({
      ...prevInputs,
      ["instance"]: instance,
    }));
    setInstanceSettingsForm((prevInputs) => ({
      ...prevInputs,
      ["settingsType"]: settingsType,
    }));
    setInstanceSettingsForm((prevInputs) => ({
      ...prevInputs,
      ["isOpen"]: true,
    }));
  }

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Descrição</Th>
            <Th>Unidade</Th>
            <Th>Grupo</Th>
            <Th>Parâmetro</Th>
            <Th w="25px">Discretizar</Th>
            <Th w="25px">Subdividir</Th>
            <Th w="25px"></Th>
            <Th w="25px"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(instances["instances"]).map(
            ([instance, values], k) => (
              <Tr key={k}>
                <Td w="700px">
                  {i + 1 + "." + (j + 1) + "." + (k + 1) + ". " + instance}
                </Td>
                <Td>
                  <Select
                    w="80px"
                    value={values.unit}
                    onChange={(ev) =>
                      onChangeInstanceUnit(
                        content,
                        category,
                        instance,
                        ev.target.value
                      )
                    }
                  >
                    {Object.keys(config["units"]).map((unit) => (
                      <option key={unit}>{unit}</option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <Select
                    w="300px"
                    value={values.group}
                    onChange={(ev) =>
                      onChangeInstanceGroup(
                        content,
                        category,
                        instance,
                        ev.target.value
                      )
                    }
                    disabled={!ifcData[composing[content][category]["ifcType"]]}
                  >
                    {ifcData[composing[content][category]["ifcType"]] &&
                      (instanceGroupExist(
                        content,
                        category,
                        composing[content][category]["instances"][instance][
                          "group"
                        ]
                      ) ||
                      composing[content][category]["instances"][instance][
                        "group"
                      ] === "Manual" ||
                      composing[content][category]["instances"][instance][
                        "group"
                      ] === "Quantidade" ? (
                        <>
                          <optgroup label="Opções Personalizadas:">
                            <option key="Manual">Manual</option>
                            <option key="Quantidade">Quantidade</option>
                          </optgroup>
                          <optgroup label="Opções do Modelo:">
                            {Object.keys(
                              ifcData[
                                composing[content][category]["ifcType"]
                              ][0]
                            ).map(
                              (parameterGroup) =>
                                parameterGroup !== "Material" && (
                                  <option
                                    key={parameterGroup}
                                    value={parameterGroup}
                                  >
                                    {parameterGroup}
                                  </option>
                                )
                            )}
                          </optgroup>
                        </>
                      ) : (
                        <>
                          <option
                            key={
                              composing[content][category]["instances"][
                                instance
                              ]["group"]
                            }
                            value={
                              composing[content][category]["instances"][
                                instance
                              ]["group"]
                            }
                          >
                            {
                              composing[content][category]["instances"][
                                instance
                              ]["group"]
                            }{" "}
                            não foi encontrado
                          </option>
                          <optgroup label="Opções Personalizadas:">
                            <option key="Manual">Manual</option>
                            <option key="Quantidade">Quantidade</option>
                          </optgroup>
                          <optgroup label="Opções do Modelo:">
                            {Object.keys(
                              ifcData[
                                composing[content][category]["ifcType"]
                              ][0]
                            ).map(
                              (parameterGroup) =>
                                parameterGroup !== "Material" && (
                                  <option
                                    key={parameterGroup}
                                    value={parameterGroup}
                                  >
                                    {parameterGroup}
                                  </option>
                                )
                            )}
                          </optgroup>
                        </>
                      ))}
                  </Select>
                </Td>
                <Td>
                  <Select
                    w="250px"
                    value={values.parameter}
                    onChange={(ev) =>
                      onChangeInstanceParameter(
                        content,
                        category,
                        instance,
                        ev.target.value
                      )
                    }
                    isDisabled={
                      values.group === "Manual" ||
                      values.group === "Quantidade" ||
                      !ifcData[composing[content][category]["ifcType"]] ||
                      !instanceGroupExist(
                        content,
                        category,
                        composing[content][category]["instances"][instance][
                          "group"
                        ]
                      )
                    }
                  >
                    {values.group !== "Manual" &&
                      values.group !== "Quantidade" &&
                      ifcData[composing[content][category]["ifcType"]] &&
                      instanceGroupExist(
                        content,
                        category,
                        composing[content][category]["instances"][instance][
                          "group"
                        ]
                      ) &&
                      (instanceParameterExist(
                        content,
                        category,
                        composing[content][category]["instances"][instance][
                          "group"
                        ],
                        composing[content][category]["instances"][instance][
                          "parameter"
                        ]
                      ) ? (
                        <optgroup label="Parâmetros do Grupo:">
                          {Object.keys(
                            ifcData[composing[content][category]["ifcType"]][0][
                              values.group
                            ]
                          ).map((parameter) => (
                            <option key={parameter} value={parameter}>
                              {parameter}
                            </option>
                          ))}
                        </optgroup>
                      ) : (
                        <optgroup label="Parâmetros do Grupo:">
                          <option
                            key={
                              composing[content][category]["instances"][
                                instance
                              ]["parameter"]
                            }
                            value={
                              composing[content][category]["instances"][
                                instance
                              ]["parameter"]
                            }
                          >
                            {
                              composing[content][category]["instances"][
                                instance
                              ]["parameter"]
                            }{" "}
                            não foi encontrado
                          </option>
                          {Object.keys(
                            ifcData[composing[content][category]["ifcType"]][0][
                              values.group
                            ]
                          ).map((parameter) => (
                            <option key={parameter} value={parameter}>
                              {parameter}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                  </Select>
                </Td>
                <Td>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Checkbox
                      borderColor="black"
                      colorScheme="blue"
                      isChecked={values["discretize"]["group"] !== "-"}
                      onChange={() =>
                        onChangeInstanceDiscretizer(
                          content,
                          category,
                          instance,
                          values.discretize
                        )
                      }
                    />
                    <IconButton
                      icon={
                        <Tooltip
                          hasArrow
                          label="Configurar Discretização"
                          bg="white"
                          color="black"
                          fontSize="md"
                        >
                          <SettingsIcon />
                        </Tooltip>
                      }
                      size="md"
                      isDisabled={values["discretize"]["group"] === "-"}
                      onClick={() =>
                        openInstanceSettingsForm(
                          content,
                          category,
                          instance,
                          "discretize"
                        )
                      }
                    />
                  </Box>
                </Td>
                <Td>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Checkbox
                      borderColor="black"
                      colorScheme="blue"
                      isChecked={values["subdivide"]["group"] !== "-"}
                      onChange={() =>
                        onChangeInstanceSubdivisor(
                          content,
                          category,
                          instance,
                          values.subdivide
                        )
                      }
                    />
                    <IconButton
                      icon={
                        <Tooltip
                          hasArrow
                          label="Configurar Subdivisão"
                          bg="white"
                          color="black"
                          fontSize="md"
                        >
                          <SettingsIcon />
                        </Tooltip>
                      }
                      size="md"
                      isDisabled={values["subdivide"]["group"] === "-"}
                      onClick={() =>
                        openInstanceSettingsForm(
                          content,
                          category,
                          instance,
                          "subdivide"
                        )
                      }
                    />
                  </Box>
                </Td>
                <Td>
                  <Popover
                    placement="bottom"
                    isOpen={formOpen === "rename_instance_" + instance}
                    onOpen={() => setFormOpen("rename_instance_" + instance)}
                    onClose={() => setFormOpen("")}
                  >
                    <PopoverTrigger>
                      <IconButton
                        icon={
                          <Tooltip
                            hasArrow
                            label="Renomear Instância"
                            bg="white"
                            color="black"
                            fontSize="md"
                          >
                            <EditIcon />
                          </Tooltip>
                        }
                        size="md"
                      />
                    </PopoverTrigger>
                    <PopoverContent flexDirection={"row"}>
                      <PopoverArrow backgroundColor={"black"} />
                      <Input
                        type="text"
                        placeholder="Digite o nome da instância"
                        onChange={(ev) => setInstanceInput(ev.target.value)}
                        value={instanceInput}
                      />
                      <IconButton
                        icon={<CheckIcon />}
                        onClick={() => {
                          onRenameInstanceAction(
                            content,
                            category,
                            instance,
                            instanceInput
                          );
                          setFormOpen("");
                          setInstanceInput("");
                        }}
                      />
                      <IconButton
                        icon={<CloseIcon />}
                        onClick={() => {
                          setFormOpen("");
                          setInstanceInput("");
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                </Td>
                <Td>
                  <IconButton
                    icon={
                      <Tooltip
                        hasArrow
                        label="Remover Instância"
                        bg="white"
                        color="black"
                        fontSize="md"
                      >
                        <DeleteIcon />
                      </Tooltip>
                    }
                    size="md"
                    onClick={() =>
                      onRemoveInstanceAction(content, category, instance)
                    }
                  />
                </Td>
              </Tr>
            )
          )}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td border="none">
              <Popover
                placement="right"
                isOpen={formOpen === "add_instance" + category}
                onOpen={() => setFormOpen("add_instance" + category)}
                onClose={() => setFormOpen("")}
              >
                <PopoverTrigger>
                  <IconButton
                    icon={
                      <Tooltip
                        hasArrow
                        label="Adicionar Instância"
                        bg="white"
                        color="black"
                        fontSize="md"
                      >
                        <AddIcon />
                      </Tooltip>
                    }
                    size="md"
                  />
                </PopoverTrigger>
                <PopoverContent flexDirection={"row"}>
                  <PopoverArrow backgroundColor={"black"} />
                  <Input
                    type="text"
                    placeholder="Digite o nome da instância"
                    onChange={(ev) => setInstanceInput(ev.target.value)}
                    value={instanceInput}
                  />
                  <IconButton
                    icon={<CheckIcon />}
                    onClick={() => {
                      onAddInstanceAction(content, category, instanceInput);
                      setFormOpen("");
                      setInstanceInput("");
                    }}
                  />
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => {
                      setFormOpen("");
                      setInstanceInput("");
                    }}
                  />
                </PopoverContent>
              </Popover>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
      <ConfigInstance
        ifcData={ifcData}
        composing={composing}
        updateComposingInputs={updateComposingInputs}
        isOpen={instanceSettingsForm["isOpen"]}
        content={instanceSettingsForm["content"]}
        category={instanceSettingsForm["category"]}
        instance={instanceSettingsForm["instance"]}
        settingsType={instanceSettingsForm["settingsType"]}
        setInstanceSettingsForm={setInstanceSettingsForm}
        categoryIfcTypeExist={categoryIfcTypeExist}
        instanceGroupExist={instanceGroupExist}
        instanceParameterExist={instanceParameterExist}
      />
    </>
  );
}

export default Instance;
