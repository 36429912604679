import React, { useState } from "react";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import styles from "../Main.module.css";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

import { api } from "../../../utils/services/api";

function IfcTable({ ifcData, composing }) {
  const toast = useToast();
  const [orderedData, setOrderedData] = useState(null);

  async function getOrderedData() {
    const loadingToastId = toast({
      title: "A sua tabela está sendo gerada",
      description: "Por favor, aguarde...",
      status: "info",
      duration: null, // Mantém o toast até que seja fechado manualmente ou atualizado
      isClosable: false, // Não permitir que o usuário feche o toast
    });

    try {
      const response = await api.post("/ifc/ordered", [ifcData, composing]);
      // Atualizar o toast com uma mensagem de sucesso
      toast.update(loadingToastId, {
        title: "Sua tabela foi gerada com sucesso!",
        description: "Verifique os resultados",
        status: "success",
        duration: 5000, // Duração de 5 segundos
        isClosable: true, // Permitir que o usuário feche o toast
      });
      // Atualizar o estado com os dados recebidos
      setOrderedData(response.data);
    } catch (error) {
      // Se ocorrer um erro, mostrar um toast de erro
      toast.update(loadingToastId, {
        title: "Erro ao gerar tabela",
        description: "Por favor, tente novamente mais tarde.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Erro ao carregar informações:", error);
    }
  }

  function exportTableToExcel() {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Planilha de Quantidades");
    sheet.columns = [
      { header: "ITEM", key: "item", width: 10 },
      { header: "DESCRIÇÃO", key: "descricao", width: 70 },
      { header: "QUANTIDADE", key: "quantidade", width: 15 },
      { header: "UNIDADE", key: "unidade", width: 10 },
    ];
    const columns = ["A", "B", "C", "D"];
    columns.map((column) => {
      sheet.getCell(column + "1").font = {
        color: { argb: "FFFFFF" },
        bold: true,
      };
      sheet.getCell(column + "1").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF002160" },
      };
      sheet.getCell(column + "1").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });
    console.log(orderedData);
    Object.entries(orderedData).map(([content, categories], i) => {
      i = i + 1;
      const contentRow = sheet.addRow({
        item: i + ".",
        descricao: content,
        quantidade: "",
        unidade: "",
      });
      columns.map((column) => {
        sheet.getCell(column + contentRow["_number"]).font = {
          color: { argb: "FFFFFF" },
          bold: true,
        };
        sheet.getCell(column + contentRow["_number"]).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0071C0" },
        };
        sheet.getCell(column + contentRow["_number"]).alignment = {
          vertical: "middle",
          horizontal: "left",
        };
      });
      Object.entries(categories).map(([category, instances], j) => {
        j = j + 1;
        const categoryRow = sheet.addRow({
          item: i + "." + j + ".",
          descricao: category,
          quantidade: "",
          unidade: "",
        });
        columns.map((column) => {
          sheet.getCell(column + categoryRow["_number"]).font = {
            color: { argb: "FFFFFF" },
          };
          sheet.getCell(column + categoryRow["_number"]).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF00B2EF" },
          };
          sheet.getCell(column + categoryRow["_number"]).alignment = {
            vertical: "middle",
            horizontal: "left",
          };
          if (column === "B") {
            sheet.getCell(column + categoryRow["_number"]).alignment = {
              vertical: "middle",
              horizontal: "left",
              indent: 1,
            };
          }
        });
        Object.entries(instances).map(
          ([instance_name, discretize_instance], k) => {
            Object.entries(discretize_instance).map(
              ([discretize_instance_name, discretize_instance_value]) => {
                if (typeof discretize_instance_value !== "object") {
                  // Normal e Discretizado
                  if (discretize_instance_value == "Manual") {
                    discretize_instance_value = "";
                  } else {
                    discretize_instance_value =
                      discretize_instance_value.toFixed(2);
                  }
                  k = k + 1;
                  const instanceRow = sheet.addRow({
                    item: i + "." + j + "." + k + ".",
                    descricao: discretize_instance_name,
                    quantidade: discretize_instance_value,
                    unidade:
                      composing[content][category]["instances"][instance_name][
                        "unit"
                      ],
                  });
                  columns.map((column) => {
                    sheet.getCell(column + instanceRow["_number"]).alignment = {
                      vertical: "middle",
                      horizontal: "left",
                    };
                    if (column === "B") {
                      sheet.getCell(column + instanceRow["_number"]).alignment =
                        {
                          vertical: "middle",
                          horizontal: "left",
                          indent: 2,
                        };
                    }
                  });
                } else {
                  k = k + 1;
                  const instanceRow = sheet.addRow({
                    item: i + "." + j + "." + k + ".",
                    descricao: discretize_instance_name,
                    quantidade: "",
                    unidade: "",
                  });
                  columns.map((column) => {
                    sheet.getCell(column + instanceRow["_number"]).alignment = {
                      vertical: "middle",
                      horizontal: "left",
                    };
                    if (column === "B") {
                      sheet.getCell(column + instanceRow["_number"]).alignment =
                        {
                          vertical: "middle",
                          horizontal: "left",
                          indent: 2,
                        };
                    }
                  });
                  Object.entries(discretize_instance_value).map(
                    (
                      [subdivide_instance_name, subdivide_instance_value],
                      l
                    ) => {
                      //Subdividido e Discretizado+Subdividido
                      if (subdivide_instance_value == "Manual") {
                        subdivide_instance_value = "";
                      } else {
                        subdivide_instance_value =
                          subdivide_instance_value.toFixed(2);
                      }
                      l = l + 1;
                      const instanceRow = sheet.addRow({
                        item: i + "." + j + "." + k + "." + l + ".",
                        descricao: subdivide_instance_name,
                        quantidade: subdivide_instance_value,
                        unidade:
                          composing[content][category]["instances"][
                            instance_name
                          ]["unit"],
                      });
                      columns.map((column) => {
                        sheet.getCell(
                          column + instanceRow["_number"]
                        ).alignment = {
                          vertical: "middle",
                          horizontal: "left",
                        };
                        if (column === "B") {
                          sheet.getCell(
                            column + instanceRow["_number"]
                          ).alignment = {
                            vertical: "middle",
                            horizontal: "left",
                            indent: 3,
                          };
                        }
                      });
                    }
                  );
                }
              }
            );
          }
        );
      });
    });

    const filename = "Planilha de Quantidades - ARS.xlsx";
    // write workbook to buffer then convert to excel file and download
    workbook.xlsx.writeBuffer().then((data) => {
      const file = new Blob([data], {
        type: "appplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(file, filename);
    });
  }

  return orderedData !== null ? (
    <div className={styles.mainTable}>
      <Accordion defaultIndex={[0]} allowMultiple>
        {Object.entries(orderedData).map(([content, categories], i) => (
          <AccordionItem key={i} borderStyle="none" padding="1px">
            <AccordionButton
              flex="1"
              bg="blue.600"
              borderRadius="100px"
              justifyContent="space-between"
            >
              <Box textColor="white" textAlign="left">
                {i + 1 + ". " + content}
              </Box>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Accordion allowMultiple>
                {Object.entries(categories).map(([category, instances], j) => (
                  <AccordionItem key={j} borderStyle="none" padding="1px">
                    <AccordionButton
                      flex="1"
                      bg="blue.300"
                      borderRadius="100px"
                      justifyContent="space-between"
                    >
                      <Box textColor="white" textAlign="left">
                        {i + 1 + "." + (j + 1) + ". " + category}
                      </Box>
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Table>
                        <Thead>
                          <Tr key={"header" + j}>
                            <Th fontWeight="bold">Descrição</Th>
                            <Th fontWeight="bold">Valor</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {Object.entries(instances).map(
                            ([instance, value], k) =>
                              // 4 situações: Normal, Discretizado, Subdividido, Discretizado e Subdividido
                              instance in value ? (
                                typeof value[instance] !== "object" ? (
                                  // Normal
                                  <Tr key={k}>
                                    <Td w="700px" fontWeight="bold">
                                      {i +
                                        1 +
                                        "." +
                                        (j + 1) +
                                        "." +
                                        (k + 1) +
                                        ". " +
                                        instance}
                                    </Td>
                                    <Td fontWeight="bold">
                                      {value[instance] === "Manual" ? (
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          alignItems="center"
                                        >
                                          {/* <Input placeholder='Digite um valor' w='200px'></Input> */}
                                          {/* <Editable defaultValue="Digite um valor">
                                            <EditablePreview />
                                            <EditableInput w="110px" />
                                          </Editable> */}
                                          <Text>-</Text>
                                          {/* <Text marginLeft="3px">
                                            {
                                              composing[content][category][
                                                "instances"
                                              ][instance]["unit"]
                                            }
                                          </Text> */}
                                        </Box>
                                      ) : (
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          alignItems="center"
                                        >
                                          <Text>
                                            {value[instance].toFixed(2)}
                                          </Text>
                                          <Text marginLeft="3px">
                                            {
                                              composing[content][category][
                                                "instances"
                                              ][instance]["unit"]
                                            }
                                          </Text>
                                        </Box>
                                      )}
                                    </Td>
                                  </Tr>
                                ) : (
                                  // Subdividido
                                  <>
                                    <Tr key={k}>
                                      <Td w="700px" fontWeight="bold">
                                        {i +
                                          1 +
                                          "." +
                                          (j + 1) +
                                          "." +
                                          (k + 1) +
                                          ". " +
                                          instance}
                                      </Td>
                                      <Td>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          alignItems="center"
                                        ></Box>
                                      </Td>
                                    </Tr>
                                    {Object.entries(value[instance]).map(
                                      (
                                        [subdivide_name, subdivide_value],
                                        l
                                      ) => (
                                        <Tr key={k + "." + l}>
                                          <Td w="700px" paddingLeft="50px">
                                            {i +
                                              1 +
                                              "." +
                                              (j + 1) +
                                              "." +
                                              (k + 1) +
                                              "." +
                                              (l + 1) +
                                              ". " +
                                              subdivide_name}
                                          </Td>
                                          <Td>
                                            {subdivide_value === "Manual" ? (
                                              <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                              >
                                                {/* <Input placeholder='Digite um valor' w='200px'></Input> */}
                                                {/* <Editable defaultValue="Digite um valor">
                                                  <EditablePreview />
                                                  <EditableInput w="110px" />
                                                </Editable> */}
                                                <Text>-</Text>
                                                {/* <Text marginLeft="3px">
                                                  {
                                                    composing[content][
                                                      category
                                                    ]["instances"][instance][
                                                      "unit"
                                                    ]
                                                  }
                                                </Text> */}
                                              </Box>
                                            ) : (
                                              <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                              >
                                                <Text>
                                                  {subdivide_value.toFixed(2)}
                                                </Text>
                                                <Text marginLeft="3px">
                                                  {
                                                    composing[content][
                                                      category
                                                    ]["instances"][instance][
                                                      "unit"
                                                    ]
                                                  }
                                                </Text>
                                              </Box>
                                            )}
                                          </Td>
                                        </Tr>
                                      )
                                    )}
                                  </>
                                )
                              ) : (
                                Object.entries(value).map(
                                  ([discretize_instance, discretize_value]) => {
                                    k = k + 1;
                                    return typeof discretize_value !==
                                      "object" ? (
                                      // Discretizado:
                                      <Tr key={k}>
                                        <Td w="700px" fontWeight="bold">
                                          {i +
                                            1 +
                                            "." +
                                            (j + 1) +
                                            "." +
                                            k +
                                            ". " +
                                            discretize_instance}
                                        </Td>
                                        <Td fontWeight="bold">
                                          {discretize_value === "Manual" ? (
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              alignItems="center"
                                            >
                                              {/* <Input placeholder='Digite um valor' w='200px'></Input> */}
                                              {/* <Editable defaultValue="Digite um valor">
                                                <EditablePreview />
                                                <EditableInput w="110px" />
                                              </Editable> */}
                                              <Text>-</Text>
                                              {/* <Text marginLeft="3px">
                                                {
                                                  composing[content][category][
                                                    "instances"
                                                  ][instance]["unit"]
                                                }
                                              </Text> */}
                                            </Box>
                                          ) : (
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              alignItems="center"
                                            >
                                              <Text>
                                                {discretize_value.toFixed(2)}
                                              </Text>
                                              <Text marginLeft="3px">
                                                {
                                                  composing[content][category][
                                                    "instances"
                                                  ][instance]["unit"]
                                                }
                                              </Text>
                                            </Box>
                                          )}
                                        </Td>
                                      </Tr>
                                    ) : (
                                      // Discretizado e Subdividido
                                      <>
                                        <Tr key={k}>
                                          <Td w="700px" fontWeight="bold">
                                            {i +
                                              1 +
                                              "." +
                                              (j + 1) +
                                              "." +
                                              k +
                                              ". " +
                                              discretize_instance}
                                          </Td>
                                          <Td>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              alignItems="center"
                                            ></Box>
                                          </Td>
                                        </Tr>
                                        {Object.entries(discretize_value).map(
                                          (
                                            [subdivide_name, subdivide_value],
                                            l
                                          ) => (
                                            <Tr key={k + "." + l}>
                                              <Td w="700px" paddingLeft="50px">
                                                {i +
                                                  1 +
                                                  "." +
                                                  (j + 1) +
                                                  "." +
                                                  (k + 1) +
                                                  "." +
                                                  (l + 1) +
                                                  ". " +
                                                  subdivide_name}
                                              </Td>
                                              <Td>
                                                {subdivide_value ===
                                                "Manual" ? (
                                                  <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                  >
                                                    {/* <Input placeholder='Digite um valor' w='200px'></Input> */}
                                                    {/* <Editable defaultValue="Digite um valor">
                                                      <EditablePreview />
                                                      <EditableInput w="110px" />
                                                    </Editable> */}
                                                    <Text>-</Text>
                                                    {/* <Text marginLeft="3px">
                                                      {
                                                        composing[content][
                                                          category
                                                        ]["instances"][
                                                          instance
                                                        ]["unit"]
                                                      }
                                                    </Text> */}
                                                  </Box>
                                                ) : (
                                                  <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                  >
                                                    <Text>
                                                      {subdivide_value.toFixed(
                                                        2
                                                      )}
                                                    </Text>
                                                    <Text marginLeft="3px">
                                                      {
                                                        composing[content][
                                                          category
                                                        ]["instances"][
                                                          instance
                                                        ]["unit"]
                                                      }
                                                    </Text>
                                                  </Box>
                                                )}
                                              </Td>
                                            </Tr>
                                          )
                                        )}
                                      </>
                                    );
                                  }
                                )
                              )
                          )}
                        </Tbody>
                      </Table>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Button
        onClick={exportTableToExcel}
        colorScheme="blue"
        variant="outline"
        flexDirection="row"
        w="250px"
        margin="2px 0px"
      >
        <CalendarIcon marginRight="10px" />
        <Text>Exportar para excel</Text>
      </Button>
    </div>
  ) : (
    <div className={styles.mainButton}>
      <Button
        onClick={getOrderedData}
        colorScheme="blue"
        variant="outline"
        w="200px"
      >
        Gerar Tabela
      </Button>
    </div>
  );
}

export default IfcTable;
