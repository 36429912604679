import { useNavigate } from "react-router-dom";
import styles from "./Main.module.css";

import Sidebar from "../../components/Sidebar";
import icon from "../homepage/utils/img/complas-icon.png";
import Work from "./pages/Work";
import Entry from "./pages/Entry";

import { FaRegBuilding } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { useState } from "react";

function Main({ userInputs }) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Obras");
  const [workInputs, setWorkInputs] = useState({
    selected_name: "",
    name_input: "",
    test_bodies: [],
    levels: [],
    towers: [],
  });
  const [worksData, setWorksData] = useState([]);

  const pages = {
    Obras: {
      icon: <FaRegBuilding size="35px" />,
      action: () => {
        setWorkInputs({
          selected_name: "",
          name_input: "",
          test_bodies: [],
          levels: [],
          towers: [],
        });
        setSelectedOption("Obras");
      },
      disabled: false,
      component: (
        <Work
          userInputs={userInputs}
          setSelectedOption={setSelectedOption}
          workInputs={workInputs}
          setWorkInputs={setWorkInputs}
          worksData={worksData}
          setWorksData={setWorksData}
        />
      ),
    },
    Lançamento: {
      icon: <FaClipboardList size="35px" />,
      action: () => setSelectedOption("Lançamento"),
      disabled: true,
      component: (
        <Entry
          userInputs={userInputs}
          workInputs={workInputs}
          worksData={worksData}
        />
      ),
    },
  };

  return userInputs["id"] !== null ? (
    <div className={styles.bodyComplas}>
      <Sidebar
        icon={icon}
        pages={pages}
        selected={selectedOption}
        condition={workInputs["selected_name"] !== ""}
      />
      {pages[selectedOption]["component"]}
    </div>
  ) : (
    navigate("/")
  );
}

export default Main;
