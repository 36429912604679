import React, { useState } from "react";
import { ChakraProvider, Box, Button, Input } from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function Filter() {
  const [items, setItems] = useState([
    { id: "content", text: "Content" },
    { id: "category", text: "Category" },
    { id: "instance", text: "Instance" },
  ]);

  const [customItems, setCustomItems] = useState([]);
  const [newItemText, setNewItemText] = useState("");

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (
      source.droppableId === "custom-items" &&
      destination.droppableId === "custom-items"
    ) {
      const reorderedItems = Array.from(customItems);
      const [removed] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, removed);

      setCustomItems(reorderedItems);
    }
  };

  const addNewItem = () => {
    if (newItemText.trim() === "") return;

    const newItem = {
      id: `custom-${customItems.length}-${newItemText}`,
      text: newItemText,
    };

    setCustomItems([...customItems, newItem]);
    setNewItemText("");
  };
  return (
    <ChakraProvider>
      <Box p={4}>
        {items.map((item) => (
          <Box key={item.id} p={2} borderWidth={1} borderRadius="md" mb={2}>
            {item.text}
          </Box>
        ))}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="custom-items">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                bg="gray.50"
                p={4}
                borderWidth={1}
                borderRadius="md"
              >
                {customItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        p={2}
                        borderWidth={1}
                        borderRadius="md"
                        mb={2}
                        bg="gray.100"
                      >
                        {item.text}
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Box mt={4}>
          <Input
            placeholder="Add new item"
            value={newItemText}
            onChange={(e) => setNewItemText(e.target.value)}
          />
          <Button onClick={addNewItem} mt={2}>
            Add Item
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Filter;
