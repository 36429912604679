import { useState, useEffect } from "react";

import {
  Radio,
  RadioGroup,
  Stack,
  Select,
  Text,
  Input,
  Button,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";

import styles from "../Main.module.css";

import soilTypes from "../utils/data/soilTypes.json";
import foundationTypes from "../utils/data/foundationTypes.json";
import foundationGeometries from "../utils/data/foundationGeometries.json";
import geotechnicsHeaders from "../utils/data/geotechnicsHeaders.json";

import {
  layerList,
  layerRegister,
  layerEdit,
  layerRemove,
  investigationRegister,
  investigationEdit,
  investigationRemove,
  geotechnicsCalculate,
  geotechnicsMemorial,
} from "../utils/services/geotechnics";

import { api } from "../../../utils/services/api";

function Geotechnics({
  userInputs,
  projectInputs,
  investigationInputs,
  updateInvestigationInputs,
  investigationsData,
  setInvestigationsData,
  foundationClass,
  setFoundationClass,
  geotechnicsInputs,
  updateGeotechnicsInputs,
  geotechnicsData,
  setGeotechnicsData,
  methodsData,
}) {
  const toast = useToast();

  // LAYERS MANAGE
  const [soilClass, setSoilClass] = useState("areia");
  const [layerInputs, setLayerInputs] = useState({
    Cota: "",
    Solo: "Areia",
    Nspt: "",
  });

  function onSoilClassChange(ev) {
    setSoilClass(ev);
    updateLayerInputs("Solo", soilTypes[ev][0]);
  }

  function updateLayerInputs(name, value) {
    setLayerInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onLayerActions(ev) {
    const action = ev.target.value;
    const options = {
      register: () => {
        if (layerInputs["Solo"] === "" || layerInputs["Nspt"] === "") {
          toast({
            title: "Preencha todos os campos da sondagem",
            status: "warning",
            isClosable: true,
          });
        } else {
          layerRegister([
            userInputs,
            projectInputs,
            investigationInputs,
            layerInputs,
          ])
            .then(() => {
              //Optimistic Update
              const updatedGeotechnicsData = [...geotechnicsData];
              updatedGeotechnicsData.push({
                Cota: updatedGeotechnicsData.length + 1,
                Solo: layerInputs["Solo"],
                Nspt: layerInputs["Nspt"],
                "Resistência Lateral": "",
                "Resistência Lateral Acumulada": "",
                "Resistência Ponta": "",
                "C. Rup. (kN)": "",
                "C. Adm. (kN)": "",
              });
              setGeotechnicsData(updatedGeotechnicsData);
              toast({
                title: "Camada de solo cadastrada com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title:
                  "Erro ao cadastrar camada de solo, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
        }
      },
      edit: () => {
        if (layerInputs["Cota"] === "") {
          toast({
            title: "Para executar essa função, selecione uma camada",
            status: "warning",
            isClosable: true,
          });
        } else if (layerInputs["Solo"] === "" || layerInputs["Nspt"] === "") {
          toast({
            title: "Preencha todos os campos da sondagem",
            status: "warning",
            isClosable: true,
          });
        } else {
          layerEdit([
            userInputs,
            projectInputs,
            investigationInputs,
            layerInputs,
          ])
            .then(() => {
              //Optimistic Update
              const updatedGeotechnicsData = [...geotechnicsData];
              updatedGeotechnicsData[layerInputs["Cota"] - 1]["Solo"] =
                layerInputs["Solo"];
              updatedGeotechnicsData[layerInputs["Cota"] - 1]["Nspt"] =
                layerInputs["Nspt"];
              setGeotechnicsData(updatedGeotechnicsData);
              toast({
                title: "Camada de solo editada com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title:
                  "Erro ao editar camada de solo, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
        }
      },
      remove: () => {
        if (layerInputs["Cota"] === "") {
          toast({
            title: "Para executar essa função, selecione uma camada",
            status: "warning",
            isClosable: true,
          });
        } else {
          layerRemove([
            userInputs,
            projectInputs,
            investigationInputs,
            layerInputs,
          ])
            .then(() => {
              //Optimistic Update
              const updatedGeotechnicsData = [...geotechnicsData];
              updatedGeotechnicsData.splice(layerInputs["Cota"] - 1, 1);
              updatedGeotechnicsData.map((layer, i) => {
                updatedGeotechnicsData[i]["Cota"] = i + 1;
              });
              setGeotechnicsData(updatedGeotechnicsData);
              toast({
                title: "Camada de solo removida com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title:
                  "Erro ao remover camada de solo, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
        }
      },
    };
    updateLayerInputs("Cota", "");
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  // INVESTIGATIONS MANAGE
  const [formOpen, setFormOpen] = useState("");

  function onInvestigationAction(action) {
    const options = {
      register: () => {
        if (investigationInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da sondagem",
            status: "warning",
            isClosable: true,
          });
        } else if (
          investigationsData.some(
            (name) => name === investigationInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma sondagem com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          investigationRegister([
            userInputs,
            projectInputs,
            investigationInputs,
          ])
            .then(() => {
              //Optimistic Update
              const updatedGeotechnicsData = [{}];
              setGeotechnicsData(updatedGeotechnicsData);
              const updatedInvestigationsData = [...investigationsData];
              updatedInvestigationsData.push(investigationInputs["name_input"]);
              setInvestigationsData(updatedInvestigationsData);
              toast({
                title: "Sondagem cadastrada com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: "Erro ao cadastrar sondagem, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
          setFormOpen("");
          updateInvestigationInputs(
            "selected_name",
            investigationInputs["name_input"]
          );
          updateInvestigationInputs("name_input", "");
        }
      },
      edit: () => {
        if (investigationInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da sondagem",
            status: "warning",
            isClosable: true,
          });
        } else if (
          investigationsData.some(
            (name) => name === investigationInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma sondagem com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          investigationEdit([userInputs, projectInputs, investigationInputs])
            .then(() => {
              //Optimistic Update
              const updatedInvestigationsData = [...investigationsData];
              updatedInvestigationsData.map((investigation, i) => {
                if (investigationInputs["selected_name"] === investigation) {
                  updatedInvestigationsData[i] =
                    investigationInputs["name_input"];
                }
              });
              setInvestigationsData(updatedInvestigationsData);
              toast({
                title: "Sondagem editada com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: "Erro ao editar sondagem, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
          setFormOpen("");
          updateInvestigationInputs(
            "selected_name",
            investigationInputs["name_input"]
          );
          updateInvestigationInputs("name_input", "");
        }
      },
      remove: () => {
        if (investigationsData.length === 1) {
          toast({
            title:
              "Essa é a única sondagem do projeto, para removê-la, apague o projeto",
            status: "error",
            isClosable: true,
          });
        } else {
          investigationRemove([userInputs, projectInputs, investigationInputs])
            .then(() => {
              //Optimistic Update
              const updatedInvestigationsData = [...investigationsData];
              updatedInvestigationsData.map((investigation, i) => {
                if (investigationInputs["selected_name"] === investigation) {
                  updatedInvestigationsData.splice(i, 1);
                }
              });
              setInvestigationsData(updatedInvestigationsData);
              toast({
                title: "Sondagem removida com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: "Erro ao remover sondagem, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
          setFormOpen("");
          updateInvestigationInputs("selected_name", investigationsData[0]);
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  useEffect(() => {
    if (projectInputs["selected_name"] !== "") {
      api
        .post("/investigation", [userInputs, projectInputs])
        .then((response) => {
          setInvestigationsData(response["data"]);
          if (investigationInputs["selected_name"] === "") {
            updateInvestigationInputs("selected_name", response["data"][0]);
          }
        });
    }
  }, []);

  // GEOTECHNICS CALCULATE
  function onCalculate() {
    if (
      foundationClass === "sapatas" &&
      (geotechnicsInputs["dimensao_1"] === "" ||
        geotechnicsInputs["dimensao_2"] === "")
    ) {
      toast({
        title: "Preencha todas as informações",
        status: "warning",
        isClosable: true,
      });
    } else if (
      foundationClass !== "sapatas" &&
      geotechnicsInputs["dimensao_1"] === ""
    ) {
      toast({
        title: "Preencha todas as informações",
        status: "warning",
        isClosable: true,
      });
    } else {
      if (foundationClass === "tubuloes") {
        geotechnicsCalculate([
          geotechnicsData,
          geotechnicsInputs,
          methodsData["estacas"][geotechnicsInputs["metodo"]],
        ]).then((response) => {
          setGeotechnicsData(response["data"]);
        });
      } else {
        geotechnicsCalculate([
          geotechnicsData,
          geotechnicsInputs,
          methodsData[foundationClass][geotechnicsInputs["metodo"]],
        ]).then((response) => {
          setGeotechnicsData(response["data"]);
        });
      }
    }
  }

  function onMemorial() {
    geotechnicsMemorial([
      geotechnicsData,
      geotechnicsInputs,
      methodsData["estacas"][geotechnicsInputs["metodo"]],
      layerInputs,
    ]).then((response) => {
      window.open(response["data"]);
    });
  }

  return (
    <>
      <div className={styles.mainGeotechnics}>
        <header className={styles.geotechnicsHeader}>
          <div className={styles.geotechnicsMenu}>
            <div className={styles.geotechnicsStep}>
              <Text>SONDAGEM:</Text>
            </div>
            <div className={styles.geotechnicsStep}>
              <RadioGroup onChange={onSoilClassChange} value={soilClass}>
                <Stack direction="row" spacing="65px">
                  <Radio
                    value="areia"
                    color="black"
                    colorScheme="blue"
                    fontSize="md"
                  >
                    Areia
                  </Radio>
                  <Radio
                    value="argila"
                    color="black"
                    colorScheme="blue"
                    fontSize="md"
                  >
                    Argila
                  </Radio>
                  <Radio
                    value="silte"
                    color="black"
                    colorScheme="blue"
                    fontSize="md"
                  >
                    Silte
                  </Radio>
                </Stack>
              </RadioGroup>
            </div>
            <div className={styles.geotechnicsStep}>
              <Text fontSize="md">Solo:</Text>
              <Select
                name="Solo"
                onChange={(ev) => updateLayerInputs("Solo", ev.target.value)}
                width="180px"
                size="xs"
                fontSize="md"
              >
                {soilTypes[soilClass].map((element) => (
                  <option key={element}>{element}</option>
                ))}
              </Select>
              <Text fontSize="md">Nspt=</Text>
              <Input
                name="Nspt"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                  if (event.target.value.toString().length > 1) {
                    event.preventDefault();
                  }
                }}
                onChange={(ev) => updateLayerInputs("Nspt", ev.target.value)}
                width="45px"
                size="xs"
                fontSize="md"
              />
            </div>
            <div className={styles.geotechnicsStep}>
              <Button
                name="register"
                width="100px"
                onClick={onLayerActions}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Cadastrar
              </Button>
              <Button
                name="edit"
                width="70px"
                onClick={onLayerActions}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Editar
              </Button>
              <Button
                name="remove"
                width="100px"
                onClick={onLayerActions}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Remover
              </Button>
            </div>
          </div>
          <div className={styles.geotechnicsMenu}>
            <div className={styles.geotechnicsStep}>
              <Text>TIPO DE FUNDAÇÃO</Text>
            </div>
            <div className={styles.geotechnicsStep}>
              <Select
                name="classe"
                onChange={(ev) => setFoundationClass(ev.target.value)}
                variant="outline"
                w="200px"
                size="xs"
                fontSize="md"
                // value={geotechnicsInputs["metodo"]}
              >
                <option key="estacas" name="estacas">
                  Estacas
                </option>
                <option key="sapatas" name="sapatas" disabled={true}>
                  Sapatas
                </option>
                <option key="tubuloes" name="tubuloes" disabled={true}>
                  Tubulões
                </option>
              </Select>
            </div>
            <div className={styles.geotechnicsStep}>
              <Text>MÉTODOS</Text>
            </div>
            <Select
              name="metodo"
              onChange={(ev) =>
                updateGeotechnicsInputs("metodo", ev.target.value)
              }
              variant="outline"
              w="200px"
              size="xs"
              fontSize="md"
              value={geotechnicsInputs["metodo"]}
            >
              {Object.keys(
                foundationClass === "tubuloes"
                  ? methodsData["estacas"]
                  : methodsData[foundationClass]
              ).map((method) => (
                <option key={method}>{method}</option>
              ))}
            </Select>
          </div>
          <div className={styles.geotechnicsMenu}>
            <div className={styles.geotechnicsStep}>
              <Stack direction="row">
                <Text fontSize="md">Tipo:</Text>
                <Select
                  name="tipo"
                  onChange={(ev) =>
                    updateGeotechnicsInputs("tipo", ev.target.value)
                  }
                  width="200px"
                  size="xs"
                  fontSize="md"
                >
                  {foundationTypes[foundationClass].map((element) => (
                    <option key={element}>{element}</option>
                  ))}
                </Select>
              </Stack>
            </div>
            <div className={styles.geotechnicsStep}>
              {foundationGeometries[geotechnicsInputs["tipo"]] ===
                "estaca circular" ||
              foundationGeometries[geotechnicsInputs["tipo"]] ===
                "sapata circular" ? (
                <Stack direction="row">
                  <Text fontSize="md">Diâmetro(m)=</Text>
                  <Input
                    name="dimensao_1"
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(ev) =>
                      updateGeotechnicsInputs("dimensao_1", ev.target.value)
                    }
                    width="50px"
                    size="xs"
                    fontSize="md"
                  />
                </Stack>
              ) : foundationGeometries[geotechnicsInputs["tipo"]] ===
                  "estaca retangular" ||
                foundationGeometries[geotechnicsInputs["tipo"]] ===
                  "sapata retangular" ? (
                <>
                  <Stack direction="row">
                    <Text fontSize="md">L(m)=</Text>
                    <Input
                      name="dimensao_1"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimensao_1", ev.target.value)
                      }
                      width="50px"
                      size="xs"
                      fontSize="md"
                    />
                  </Stack>
                  <Stack direction="row">
                    <Text fontSize="md">B(m)=</Text>
                    <Input
                      name="dimensao_2"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimensao_2", ev.target.value)
                      }
                      width="50px"
                      size="xs"
                      fontSize="md"
                    />
                  </Stack>
                </>
              ) : (
                <>
                  <Stack direction="row">
                    <Text fontSize="md">Df(m)=</Text>
                    <Input
                      name="dimensao_1"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimensao_1", ev.target.value)
                      }
                      width="50px"
                      size="xs"
                      fontSize="md"
                    />
                  </Stack>
                  <Stack direction="row">
                    <Text fontSize="md">Db(m)=</Text>
                    <Input
                      name="dimensao_2"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimensao_2", ev.target.value)
                      }
                      width="50px"
                      size="xs"
                      fontSize="md"
                    />
                  </Stack>
                </>
              )}
            </div>
            <div className={styles.geotechnicsStep}>
              {foundationClass === "sapatas" ? (
                <>
                  <Stack direction="row">
                    <Text fontSize="md">Prof.+</Text>
                    <Select
                      name="dimensao_3"
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimensao_3", ev.target.value)
                      }
                      width="65px"
                      size="xs"
                      fontSize="md"
                    >
                      {[0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9].map(
                        (element) => (
                          <option key={element}>{element}</option>
                        )
                      )}
                    </Select>
                  </Stack>
                  <Stack direction="row">
                    <Text fontSize="md">N.A.(m)=</Text>
                    <Select
                      name="dimensao_4"
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimensao_4", ev.target.value)
                      }
                      width="55px"
                      size="xs"
                      fontSize="md"
                    >
                      {[...Array(geotechnicsData.length + 2).keys()].map(
                        (element) => (
                          <option key={element}>{element}</option>
                        )
                      )}
                    </Select>
                  </Stack>
                </>
              ) : (
                <Stack direction="row">
                  <Text fontSize="md">C.A.(m)=</Text>
                  <Select
                    name="dimensao_3"
                    onChange={(ev) =>
                      updateGeotechnicsInputs("dimensao_3", ev.target.value)
                    }
                    width="55px"
                    size="xs"
                    fontSize="md"
                  >
                    {[...Array(geotechnicsData.length).keys()].map(
                      (element) => (
                        <option key={element}>{element}</option>
                      )
                    )}
                  </Select>
                </Stack>
              )}
            </div>
            <div className={styles.geotechnicsStep}>
              <>
                <Button
                  name="calculate"
                  width="240px"
                  onClick={onCalculate}
                  colorScheme="blue"
                  size="sm"
                  fontSize="md"
                >
                  Calcular
                </Button>
                <IconButton
                  variant="icon"
                  size="sm"
                  icon={
                    <Tooltip
                      hasArrow
                      label="Gerar Memorial"
                      bg="gray"
                      color="black"
                      fontSize="md"
                    >
                      <CalendarIcon />
                    </Tooltip>
                  }
                  onClick={onMemorial}
                />
              </>
            </div>
          </div>
        </header>
        <nav>
          {/* <>
          <Tabs size="sm" variant="enclosed">
            <TabList>
              <Tab
                name="compressao"
                onClick={onGeotechnicsStressChange}
                fontSize="md"
              >
                Compressão
              </Tab>
              <Tab
                name="tracao"
                onClick={onGeotechnicsStressChange}
                _selected={{ color: "white", bg: "blue" }}
                fontSize="md"
              >
                Tração
              </Tab>
            </TabList>
          </Tabs>
        </> */}
        </nav>
        <div className={styles.geotechnicsSection}>
          <Table variant="simple">
            <Thead>
              <Tr>
                {geotechnicsHeaders[foundationClass].map((h, i) => (
                  <Th key={"th" + i}>{h}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {geotechnicsData.length !== 0 ? (
                geotechnicsData.map((camada, i) => (
                  <Tr
                    aria-selected={
                      layerInputs["Cota"] === i + 1 ? "true" : "false"
                    }
                    key={"row-" + i}
                  >
                    {geotechnicsHeaders[foundationClass].map((h, _i) => {
                      return (
                        camada[h] !== undefined && (
                          <Td
                            key={"col-" + _i}
                            onClick={() => updateLayerInputs("Cota", i + 1)}
                          >
                            {h === "Cota"
                              ? camada[h] - 1 + "-" + camada[h]
                              : camada[h]}
                          </Td>
                        )
                      );
                    })}
                  </Tr>
                ))
              ) : (
                <Tr key={"row-0"}>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </div>
        <footer className={styles.geotechnicsFooter}>
          <Select
            w="200px"
            onChange={(ev) =>
              updateInvestigationInputs("selected_name", ev.target.value)
            }
            value={investigationInputs["selected_name"]}
          >
            {investigationsData.map((name, i) => (
              <option key={i} value={name}>
                {" "}
                {name}{" "}
              </option>
            ))}
          </Select>
          <Popover
            placement="bottom"
            isOpen={formOpen === "register"}
            onOpen={() => setFormOpen("register")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                variant="icon"
                icon={
                  <Tooltip
                    hasArrow
                    label="Criar"
                    bg="gray"
                    color="black"
                    fontSize="md"
                  >
                    <AddIcon />
                  </Tooltip>
                }
              />
            </PopoverTrigger>
            <PopoverContent flexDirection={"row"}>
              <PopoverArrow backgroundColor={"black"} />
              <Input
                type="text"
                placeholder="Digite o nome da sondagem"
                onChange={(ev) =>
                  updateInvestigationInputs("name_input", ev.target.value)
                }
                value={investigationInputs["name_input"]}
              />
              <IconButton
                variant="icon"
                icon={<CheckIcon />}
                onClick={() => onInvestigationAction(formOpen)}
              />
              <IconButton
                variant="icon"
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  updateInvestigationInputs("name_input", "");
                }}
              />
            </PopoverContent>
          </Popover>
          <Popover
            placement="bottom"
            isOpen={formOpen === "edit"}
            onOpen={() => setFormOpen("edit")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                variant="icon"
                icon={
                  <Tooltip
                    hasArrow
                    label="Editar"
                    bg="gray"
                    color="black"
                    fontSize="md"
                  >
                    <EditIcon />
                  </Tooltip>
                }
              />
            </PopoverTrigger>
            <PopoverContent flexDirection={"row"}>
              <PopoverArrow backgroundColor={"black"} />
              <Input
                type="text"
                placeholder="Digite o nome da sondagem"
                onChange={(ev) =>
                  updateInvestigationInputs("name_input", ev.target.value)
                }
                value={investigationInputs["name_input"]}
              />
              <IconButton
                variant="icon"
                icon={<CheckIcon />}
                onClick={() => onInvestigationAction(formOpen)}
              />
              <IconButton
                variant="icon"
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  updateInvestigationInputs("name_input", "");
                }}
              />
            </PopoverContent>
          </Popover>
          <IconButton
            variant="icon"
            icon={
              <Tooltip
                hasArrow
                label="Remover"
                bg="gray"
                color="black"
                fontSize="md"
              >
                <DeleteIcon />
              </Tooltip>
            }
            onClick={() => setFormOpen("remove")}
          />
        </footer>
      </div>
      {formOpen === "remove" && investigationInputs["selected_name"] !== "" && (
        <Modal isCentered isOpen={formOpen !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Excluir {investigationInputs["selected_name"]}
            </ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormOpen("");
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button
                variant="delete"
                onClick={() => onInvestigationAction(formOpen)}
              >
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Geotechnics;
