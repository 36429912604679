import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import * as jwt_decode from "jwt-decode";

import Homepage from "./applications/homepage/Main";
import Fundars from "./applications/fundars/Main";
import Quantitars from "./applications/quantitars/Main";
import Complas from "./applications/complas/Main";

function App() {
  {
    /* Fixed because of database shutdown */
  }
  const [userInputs, setUserInputs] = useState({
    id: window.localStorage.getItem("user_id"),
    name: window.localStorage.getItem("user_name"),
  });
  // const [userInputs, setUserInputs] = useState({
  //   id: "29",
  //   name: "felipe dieguez",
  // });
  // const [userInputs, setUserInputs] = useState("HOLD");

  function updateUserInputs(key, value) {
    setUserInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Homepage
                userInputs={userInputs}
                updateUserInputs={updateUserInputs}
              />
            }
          />
          <Route
            path="/fundars/*"
            element={<Fundars userInputs={userInputs} />}
          />
          <Route
            path="/quantitars"
            element={<Quantitars userInputs={userInputs} />}
          />
          <Route
            path="/complas"
            element={<Complas userInputs={userInputs} />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
