import { border, Editable, extendTheme, IconButton } from "@chakra-ui/react";
import { color, distance2D } from "framer-motion";
import { BsBorderWidth } from "react-icons/bs";

const theme = {
  colors: {
    primary: "blue",
    second: "red",
    white: "#ffffff",
    gray: "#F9F9F9",
    border: "#ffffff",
    selected: "#d9d9d9",
  },
  components: {
    Tooltip: {
      baseStyle: {
        fontFamily: "var(--font-family)",
      },
      sizes: {
        md: {
          fontSize: "var(--font-size-base)",
        },
      },
      variants: {
        solid: { bg: "var(--background-color)", color: "var(--text-color)" },
      },
      defaultProps: {
        size: "md",
        variant: "solid",
      },
    },
    Button: {
      baseStyle: {
        fontFamily: "var(--font-family)",
        fontWeight: "bold",
        borderRadius: "var(--border-radius)",
      },
      sizes: {
        md: {
          fontSize: "var(--font-size-base)",
          px: "40px",
        },
      },
      variants: {
        solid: {
          bg: "var(--primary-color)",
          color: "white",
          _hover: {
            color: "var(--accent-light-color)",
          },
        },
        delete: {
          bg: "red",
          color: "white",
          _hover: {
            color: "var(--accent-light-color)",
          },
        },
        icon: {
          color: "var(--text-color)",
          _hover: {
            color: "var(--accent-light-color)",
          },
        },
      },
    },
    Table: {
      baseStyle: {
        table: {
          width: "100%",
          height: "100%",
        },
        thead: {
          position: "sticky",
          top: "0",
          zIndex: 1, // Adicione zIndex para garantir que fique acima de outras linhas
          tr: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            th: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size-base)",
              borderBottom: "none", // Remove o borderBottom do cabeçalho
              width: "100%",
            },
          },
        },
        tbody: {
          tr: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            td: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size-base)",
              borderBottom: "none", // Remove o borderBottom das células
              width: "100%",
            },
          },
        },
      },
      defaultProps: {
        variant: "manager",
      },
      variants: {
        manager: {
          table: {
            boxShadow: "var(--shadow)",
            overflow: "hidden",
            borderRadius: "30px",
          },
          thead: {
            bg: "var(--background-dark-color)",
            tr: {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              th: {
                color: "var(--text-color)",
                fontWeight: "bold",
              },
            },
          },
          tbody: {
            tr: {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              bg: "var(--background-color)",
              td: {
                color: "var(--text-color)",
              },
              _hover: {
                cursor: "pointer",
                td: { color: "var(--accent-color)" },
              },
              "&[aria-selected='true']": {
                td: { bg: "var(--background-dark-color)" },
              },
            },
          },
        },
        simple: {
          thead: {
            bg: "var(--background-color)",
            tr: {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              borderBottom: "1px solid var(--secondary-light-color)",
              th: {
                textAlign: "center",
                color: "var(--primary-color)",
                fontWeight: "normal",
                padding: "4.3px",
              },
            },
          },
          tbody: {
            tr: {
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              bg: "var(--background-color)",
              borderTop: "1px solid var(--secondary-light-color)", // Cor discreta da borda
              _first: {
                borderTop: "none",
              },
              _last: {
                borderBottom: "1px solid var(--secondary-light-color)", // Só para a última linha
              },
              td: {
                color: "var(--text-color)",
                padding: "3.9px",
              },
              _hover: {
                td: { color: "var(--accent-color)" },
              },
              "&[aria-selected='true']": {
                bg: "var(--background-dark-color)",
              },
            },
          },
        },
        colorful: {
          thead: {
            bg: "var(--background-color)",
            tr: {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              borderBottom: "1px solid var(--secondary-light-color)",
              th: {
                color: "var(--primary-color)",
                fontWeight: "normal",
                padding: "4.3px",
              },
            },
          },
          tbody: {
            tr: {
              bg: "var(--background-color)",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              borderBottom: "1px solid var(--secondary-light-color)",
              td: {
                cursor: "default",
                color: "var(--text-color)",
                padding: "4.3px",
              },
              "&[aria-selected='true']": {
                bg: "var(--background-dark-color)",
              },
            },
          },
        },
        subtable: {
          thead: {
            tr: {
              bg: "var(--background-color)",
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              boxShadow: "none",
              alignItems: "center",
              borderBottom: "none",
              "&[aria-selected='true']": {
                bg: "var(--background-dark-color)",
              },
            },
          },
          tbody: {
            tr: {
              bg: "var(--background-color)",
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              boxShadow: "none",
              alignItems: "center",
              borderBottom: "none",
              td: {
                cursor: "default",
                color: "var(--text-color)",
                padding: "4.3px",
              },
              "&[aria-selected='true']": {
                bg: "var(--background-dark-color)",
              },
            },
          },
        },
      },
    },
    Editable: {
      baseStyle: {
        preview: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size-base)",
          fontWeight: "bold",
        },
        input: {
          textAlign: "center",
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size-base)",
          fontWeight: "bold",
        },
      },
      defaultProps: {
        variant: "normal",
      },
      variants: {
        normal: {
          preview: {
            color: "var(--text-color)",
          },
        },
        red: {
          preview: {
            color: "red",
          },
        },
        green: {
          preview: {
            color: "green",
          },
        },
      },
    },
    Accordion: {
      baseStyle: {
        container: {
          border: "none",
        },
        button: {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size-base)",
          fontWeight: "bold",
          borderRadius: "100px",
          bg: "var(--background-dark-color)",
        },
      },
    },
    Modal: {
      baseStyle: {
        bg: "var(--background-color)",
      },
    },
  },
};

export default extendTheme(theme);
