import { useState, useEffect } from "react";

import styles from "../Main.module.css";

import {
  Button,
  Select,
  Input,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  ModalFooter,
} from "@chakra-ui/react";
import {
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";

import solutionHeaders from "../utils/data/solutionHeaders.json";
import pileCapTypes from "../utils/data/pileCapTypes.json";

import { api } from "../../../utils/services/api";

import {
  columnRegister,
  columnEdit,
  columnRemove,
} from "../utils/services/structure";

import {
  solutionRegister,
  solutionEdit,
  solutionRemove,
} from "../utils/services/structure";

import { pileLengthCalculate } from "../utils/services/structure";

function Structure({
  userInputs,
  projectInputs,
  geotechnicsInputs,
  geotechnicsData,
  investigationInputs,
  foundationClass,
}) {
  const toast = useToast();

  // SOLUTIONS MANAGER
  const [formSolution, setFormSolution] = useState("");
  const [solutionInputs, setSolutionInputs] = useState({
    selected_name: "",
    name_input: "",
  });
  const [solutionsData, setSolutionsData] = useState([]);

  function updateSolutionInputs(name, value) {
    setSolutionInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  }

  function onSolutionAction(action) {
    const options = {
      register: () => {
        if (solutionInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da solução",
            status: "warning",
            isClosable: true,
          });
        } else if (
          solutionsData.some(
            (solution) => solution === solutionInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma solução com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          solutionRegister([userInputs, projectInputs, solutionInputs])
            .then(() => {
              //Optimistic Update
              const updatedSolutionsData = [...solutionsData];
              updatedSolutionsData.push(solutionInputs["name_input"]);
              setSolutionsData(updatedSolutionsData);
              toast({
                title: "Solução cadastrada com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: "Erro ao cadastrar solução, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
          setFormSolution("");
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            selected_name: solutionInputs["name_input"],
          }));
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            name_input: "",
          }));
        }
      },
      edit: () => {
        if (solutionInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da solução",
            status: "warning",
            isClosable: true,
          });
        } else if (
          solutionsData.some(
            (solution) => solution === solutionInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma solução com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          solutionEdit([userInputs, projectInputs, solutionInputs])
            .then(() => {
              //Optimistic Update
              const updatedSolutionsData = [...solutionsData];
              updatedSolutionsData.map((solution, i) => {
                if (solutionInputs["selected_name"] === solution) {
                  updatedSolutionsData[i] = solutionInputs["name_input"];
                }
              });
              setSolutionsData(updatedSolutionsData);
              toast({
                title: "Solução editada com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: "Erro ao editar solução, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
          setFormSolution("");
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            selected_name: solutionInputs["name_input"],
          }));
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            name_input: "",
          }));
        }
      },
      remove: () => {
        if (solutionsData.length === 1) {
          toast({
            title:
              "Essa é a única sondagem do projeto, para removê-la, apague o projeto",
            status: "error",
            isClosable: true,
          });
        } else {
          solutionRemove([userInputs, projectInputs, solutionInputs])
            .then(() => {
              //Optimistic Update
              const updatedSolutionsData = [...solutionsData];
              updatedSolutionsData.map((solution, i) => {
                if (solutionInputs["selected_name"] === solution) {
                  updatedSolutionsData.splice(i, 1);
                }
              });
              setSolutionsData(updatedSolutionsData);
              toast({
                title: "Solução removida com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: "Erro ao remover solução, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
          setFormSolution("");
          setSolutionInputs((prevInputs) => ({
            ...prevInputs,
            selected_name: solutionsData[0],
          }));
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  useEffect(() => {
    if (projectInputs["selected_name"] !== "") {
      api.post("/solution", [userInputs, projectInputs]).then((response) => {
        setSolutionsData(response["data"]);
      });
    }
  }, [projectInputs["selected_name"]]);

  // COLUMNS MANAGER
  const [formColumn, setFormColumn] = useState(false);
  const [columnInputs, setColumnInputs] = useState({
    selected_name: "",
    name: "",
    fz: 0,
    mx: 0,
    my: 0,
  });
  const [columnsData, setColumnsData] = useState([]);

  function updateColumnInputs(name, value) {
    setColumnInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  }

  function onColumnAction(action) {
    const options = {
      register: () => {
        if (
          columnInputs["name"] === "" ||
          columnInputs["fz"] === "" ||
          columnInputs["mx"] === "" ||
          columnInputs["my"] === ""
        ) {
          toast({
            title: "Preencha todos os campos do pilar",
            status: "warning",
            isClosable: true,
          });
        } else if (
          columnsData.some((column) => column["name"] === columnInputs["name"])
        ) {
          toast({
            title:
              "Um pilar com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          columnRegister([userInputs, projectInputs, columnInputs]).then(() => {
            // setUpdateColumns((prev) => prev + 1);
          });
          setFormSolution("");
        }
      },
      edit: () => {
        columnEdit([userInputs, projectInputs, columnInputs]).then(() => {
          // setUpdateColumns((prev) => prev + 1);
        });
        setColumnInputs((prevInputs) => ({
          ...prevInputs,
          ["selected_name"]: "",
        }));
        setFormSolution("");
      },
      remove: () => {
        columnRemove([userInputs, projectInputs, columnInputs]).then(() => {
          // setUpdateColumns((prev) => prev + 1);
        });
        setColumnInputs((prevInputs) => ({
          ...prevInputs,
          ["selected_name"]: "",
        }));
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  useEffect(() => {
    if (projectInputs["selected_name"] !== "") {
      api
        .post("/column", [userInputs, projectInputs, solutionInputs])
        .then((response) => {
          console.log(response["data"]);
          setColumnsData(response["data"]);
          updateColumnInputs("selected_name", response["data"][0]["name"]);
          updateColumnInputs("fz", response["data"][0]["fz"]);
          updateColumnInputs("mx", response["data"][0]["mx"]);
          updateColumnInputs("my", response["data"][0]["my"]);
        });
    }
  }, [projectInputs["selected_name"]]);

  // FOUNDATION SOLUTION
  const [foundationInputs, setFoundationInputs] = useState({
    name: "",
    l: "",
    n: 1,
    rc: 0,
    rt: 0,
    clength: 0,
    tlength: 0,
    length: 1,
    radm: "",
  });
  const [foundationsData, setFoundationsData] = useState([]);

  function updateFoundationInputs(ev) {
    const { name, value } = ev.target;
    setFoundationInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onPileLengthResultsCalculate() {
    pileLengthCalculate([geotechnicsData, columnInputs, foundationInputs]).then(
      (response) => {
        // setPileResults(response["data"]);
      }
    );
  }

  return (
    <>
      <div className={styles.mainStructure}>
        <div className={styles.sectionGeotechnics}>
          <Text>
            Sondagem: <strong>{investigationInputs["selected_name"]}</strong>
          </Text>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Cota</Th>
                <Th>(-) C. Adm (kn)</Th>
                {/* <Th>(+) C. Adm (kn)</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {geotechnicsData.map((camada, i) => (
                <Tr key={"row-" + i}>
                  <Td>{camada["Cota"]}</Td>
                  <Td>{camada["C. Adm. (kN)"]}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <div className={styles.sectionStructure}>
          <div className={styles.structureHeader}>
            <Select
              name="selected_name"
              onChange={(ev) =>
                updateSolutionInputs("selected_name", ev.target.value)
              }
              variant="outline"
              w="150px"
              size="sm"
              fontSize="md"
              value={solutionInputs["selected_name"]}
            >
              {solutionsData.map((solution, i) => (
                <option key={i} value={solution}>
                  {" "}
                  {solution}{" "}
                </option>
              ))}
            </Select>
            <Popover
              placement="bottom"
              isOpen={formSolution === "register"}
              onOpen={() => setFormSolution("register")}
              onClose={() => setFormSolution("")}
            >
              <PopoverTrigger>
                <IconButton
                  variant="icon"
                  icon={
                    <Tooltip
                      hasArrow
                      label="Criar"
                      bg="gray"
                      color="black"
                      fontSize="md"
                    >
                      <AddIcon />
                    </Tooltip>
                  }
                />
              </PopoverTrigger>
              <PopoverContent flexDirection={"row"}>
                <PopoverArrow backgroundColor={"black"} />
                <Input
                  name="name_input"
                  type="text"
                  placeholder="Digite o nome da solução"
                  onChange={(ev) =>
                    updateSolutionInputs("name_input", ev.target.value)
                  }
                  value={solutionInputs["name_input"]}
                />
                <IconButton
                  variant="icon"
                  icon={<CheckIcon />}
                  onClick={() => onSolutionAction(formSolution)}
                />
                <IconButton
                  variant="icon"
                  icon={<CloseIcon />}
                  onClick={() => {
                    setFormSolution("");
                    updateSolutionInputs("name_input", "");
                  }}
                />
              </PopoverContent>
            </Popover>
            <Popover
              placement="bottom"
              isOpen={formSolution === "edit"}
              onOpen={() => setFormSolution("edit")}
              onClose={() => setFormSolution("")}
            >
              <PopoverTrigger>
                <IconButton
                  variant="icon"
                  icon={
                    <Tooltip
                      hasArrow
                      label="Editar"
                      bg="gray"
                      color="black"
                      fontSize="md"
                    >
                      <EditIcon />
                    </Tooltip>
                  }
                />
              </PopoverTrigger>
              <PopoverContent flexDirection={"row"}>
                <PopoverArrow backgroundColor={"black"} />
                <Input
                  name="name_input"
                  type="text"
                  placeholder="Digite o nome da solução"
                  onChange={(ev) =>
                    updateSolutionInputs("name_input", ev.target.value)
                  }
                  value={solutionInputs["name_input"]}
                />
                <IconButton
                  variant="icon"
                  icon={<CheckIcon />}
                  onClick={() => onSolutionAction(formSolution)}
                />
                <IconButton
                  variant="icon"
                  icon={<CloseIcon />}
                  onClick={() => {
                    setFormSolution("");
                    updateSolutionInputs("name_input", "");
                  }}
                />
              </PopoverContent>
            </Popover>
            <IconButton
              variant="icon"
              icon={
                <Tooltip
                  hasArrow
                  label="Remover"
                  bg="gray"
                  color="black"
                  fontSize="md"
                >
                  <DeleteIcon />
                </Tooltip>
              }
              onClick={() => setFormSolution("remove")}
            />
          </div>
          <div className={styles.structureSection}>
            <div className={styles.structureSectionRow}>
              <div>ESFORÇOS:</div>
            </div>
            <div className={styles.structureSectionRow}>
              <Button
                name="manage"
                width="100px"
                onClick={() => {
                  setFormColumn(true);
                }}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Gerenciar
              </Button>
              <Select
                name="column"
                onChange={(ev) => {
                  updateColumnInputs("selected_name", ev.target.value);
                  columnsData.map((column, i) => {
                    if (column["name"] === ev.target.value) {
                      updateColumnInputs("fz", column["fz"]);
                      updateColumnInputs("mx", column["mx"]);
                      updateColumnInputs("my", column["my"]);
                    }
                  });
                }}
                variant="outline"
                w="150px"
                size="xs"
                fontSize="md"
                value={columnInputs["selected_name"]}
              >
                {columnsData.map((column, i) => (
                  <option key={i} value={column["name"]}>
                    {" "}
                    {column["name"]}{" "}
                  </option>
                ))}
              </Select>
              {columnsData.map((column, i) => {
                if (columnInputs["selected_name"] === column["name"]) {
                  return (
                    <>
                      <Text fontSize="md">Fz= {column["fz"]} kN</Text>
                      <Text fontSize="md">Mx= {column["mx"]} kNm</Text>
                      <Text fontSize="md">My= {column["my"]} kNm</Text>
                    </>
                  );
                }
              })}
            </div>
            <div className={styles.structureSectionRow}>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">L(m)=</Text>
                <Input
                  name="l"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={updateFoundationInputs}
                  value={foundationInputs["l"]}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">Nº de estacas=</Text>
                <Select
                  name="n"
                  onChange={updateFoundationInputs}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={foundationInputs["n"]}
                >
                  {pileCapTypes["pileQuantities"].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
              <Button
                name="calculate_1"
                onClick={onPileLengthResultsCalculate}
                size="sm"
              >
                Calcular Comprimento
              </Button>
            </div>
            <div className={styles.structureSectionRow}>
              <Text fontSize="md">Rc= {foundationInputs["rc"]} kN</Text>
              <Text fontSize="md">Rt= {foundationInputs["rt"]} kN</Text>
              <Text fontSize="md">
                Compressão= {foundationInputs["clength"]} m
              </Text>
              <Text fontSize="md">Tração= {foundationInputs["tlength"]} m</Text>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">Adot.(m)=</Text>
                <Select
                  name="length"
                  onChange={updateFoundationInputs}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={foundationInputs["length"]}
                >
                  {[...Array(geotechnicsData.length).keys()].map((element) => (
                    <option key={element + 1}>{element + 1}</option>
                  ))}
                </Select>
              </div>
            </div>
            <Table variant="simple">
              <Thead>
                <Tr key="solution-header">
                  {solutionHeaders[foundationClass].map((header, i) => (
                    <Th key={"th" + i}>{header}</Th>
                  ))}
                </Tr>
              </Thead>
            </Table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={formColumn}
        onClose={() => {
          setFormColumn(false);
          updateColumnInputs("selected_name", columnsData[0]["name"]);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">LISTA DE PILARES</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={styles.structureSectionRow}>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">Nome=</Text>
                <Input
                  name="name"
                  onChange={(ev) => updateColumnInputs("name", ev.target.value)}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">Fz=</Text>
                <Input
                  name="fz"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(ev) => updateColumnInputs("fz", ev.target.value)}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">Mx=</Text>
                <Input
                  name="mx"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(ev) => updateColumnInputs("mx", ev.target.value)}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">My=</Text>
                <Input
                  name="my"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(ev) => updateColumnInputs("my", ev.target.value)}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
            </div>
            <div className={styles.structureSectionRow}>
              <Button
                name="column_register"
                width="100px"
                onClick={() => {
                  onColumnAction("register");
                }}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Cadastrar
              </Button>
              <Button
                name="column_edit"
                width="100px"
                onClick={() => {
                  onColumnAction("edit");
                }}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Editar
              </Button>
              <Button
                name="column_remove"
                width="100px"
                onClick={() => {
                  onColumnAction("remove");
                }}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Remover
              </Button>
            </div>
            <section>
              <TableContainer width="100%" height="500px">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      {solutionHeaders["columns"].map((header) => (
                        <Th fontSize="md">{header}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {columnsData.map((column, j) => (
                      <Tr
                        aria-selected={
                          columnInputs["selected_name"] === column["name"]
                            ? "true"
                            : "false"
                        }
                        key={"row-" + j}
                      >
                        <Td
                          key={"col-" + j}
                          onClick={() => {
                            updateColumnInputs("selected_name", column["name"]);
                          }}
                        >
                          {column["name"]}
                        </Td>
                        <Td
                          key={"col-" + j}
                          onClick={() => {
                            updateColumnInputs("selected_name", column["name"]);
                          }}
                        >
                          {column["fz"]}
                        </Td>
                        <Td
                          key={"col-" + j}
                          onClick={() => {
                            updateColumnInputs("selected_name", column["name"]);
                          }}
                        >
                          {column["mx"]}
                        </Td>
                        <Td
                          key={"col-" + j}
                          onClick={() => {
                            updateColumnInputs("selected_name", column["name"]);
                          }}
                        >
                          {column["my"]}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </section>
          </ModalBody>
        </ModalContent>
      </Modal>
      {formSolution === "remove" && solutionInputs["selected_name"] !== "" && (
        <Modal isCentered isOpen={formSolution !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Excluir {solutionInputs["selected_name"]}</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormSolution("");
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button
                variant="delete"
                onClick={() => onSolutionAction(formSolution)}
              >
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Structure;
