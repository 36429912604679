import { api } from "../../../../utils/services/api";

export function workList(data) {
  return api.post("/work", data);
}

export function workRegister(data) {
  return api.post("/work/register", data);
}

export function workEdit(data) {
  return api.post("/work/edit", data);
}

export function workRemove(data) {
  return api.post("/work/remove", data);
}

export function concretingRegister(data) {
  return api.post("/concreting/register", data);
}

export function concretingEdit(data) {
  return api.post("/concreting/edit", data);
}

export function concretingRemove(data) {
  return api.post("/concreting/remove", data);
}

export function truckRegister(data) {
  return api.post("/truck/register", data);
}

export function truckEdit(data) {
  return api.post("/truck/edit", data);
}

export function truckRemove(data) {
  return api.post("/truck/remove", data);
}
