import styles from "../Main.module.css";
import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  FormControl,
  FormLabel,
  ModalBody,
  Input,
  ModalContent,
  ModalFooter,
  Button,
  useToast,
  Editable,
  EditablePreview,
  EditableInput,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverFooter,
  PopoverCloseButton,
  Select,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";

import { api } from "../../../utils/services/api";

import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
} from "@chakra-ui/icons";

import {
  concretingRegister,
  concretingEdit,
  concretingRemove,
  truckRegister,
  truckEdit,
  truckRemove,
} from "../utils/services/complas";

function Home({ userInputs, workInputs, worksData }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [searchInputs, setSearchInputs] = useState({
    search: "",
    tower: "",
    level: "",
    initial_date: "",
    final_date: "",
    fck: "",
  });
  const [workTestBodies, setWorkTestBodies] = useState([]);
  const [workLevels, setWorkLevels] = useState([]);
  const [workTowers, setWorkTowers] = useState([]);

  const [formConcreting, setFormConcreting] = useState("");
  const [concretingInputs, setConcretingInputs] = useState({
    date: "",
    tower: "",
    level: "",
    description: "",
    fck: "",
    tower_selected: "",
    level_selected: "",
    description_selected: "",
  });
  const [concretingsData, setConcretingsData] = useState([]);
  const [filteredConcretings, setFilteredConcretings] = useState([]);

  const [formTruck, setFormTruck] = useState("");
  const [truckInputs, setTruckInputs] = useState({
    waybill: "",
    element: "",
    volume: "",
    test_bodies: [],
    waybill_selected: "",
  });

  const toggleRow = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Collapse the row if it's already expanded
    } else {
      setExpandedIndex(index); // Expand the clicked row
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  function updateSearchInputs(key, value) {
    setSearchInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateConcretingInputs(key, value) {
    setConcretingInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateTruckInputs(key, value) {
    setTruckInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function onSearchTermChange(search) {
    const filteredConcretingsData = [...concretingsData];
    const filter = filteredConcretingsData.filter((concreting) => {
      let place = null;
      if (concreting["tower"] !== "") {
        place = concreting["tower"] + " - " + concreting["level"];
        if (concreting["description"] !== "") {
          place =
            concreting["tower"] +
            " - " +
            concreting["level"] +
            " - " +
            concreting["description"];
        }
      } else {
        place = concreting["level"];
        if (concreting["description"] !== "") {
          place = concreting["level"] + " - " + concreting["description"];
        }
      }
      return place.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredConcretings(filter);
  }

  function onFilterAction(key, value) {
    updateSearchInputs(key, value);
    const updatedSearchInputs = { ...searchInputs };
    const filteredConcretingsData = [...concretingsData];
    updatedSearchInputs[key] = value;
    const { tower, level, initial_date, final_date, fck } = updatedSearchInputs;
    const filter = filteredConcretingsData.filter((info) => {
      const search_tower = info["tower"]
        .toLowerCase()
        .includes(tower.toLowerCase());
      const search_level = info["level"]
        .toLowerCase()
        .includes(level.toLowerCase());
      const dateMatch =
        initial_date && final_date
          ? info.date >= initial_date && info.date <= final_date
          : initial_date
            ? info.date === initial_date
            : true;
      const fckMatch = fck ? info.fck === fck : true;

      return search_tower && search_level && dateMatch && fckMatch;
    });
    setFilteredConcretings(filter);
    setExpandedIndex(null);
  }

  function onFilterUpdate(updatedConcretingsData) {
    const filteredConcretingsData = [...updatedConcretingsData];
    const { tower, level, initial_date, final_date, fck } = searchInputs;
    const filter = filteredConcretingsData.filter((info) => {
      const search_tower = info["tower"]
        .toLowerCase()
        .includes(tower.toLowerCase());
      const search_level = info["level"]
        .toLowerCase()
        .includes(level.toLowerCase());
      const dateMatch =
        initial_date && final_date
          ? info.date >= initial_date && info.date <= final_date
          : initial_date
            ? info.date === initial_date
            : true;
      const fckMatch = fck ? info.fck === fck : true;

      return search_tower && search_level && dateMatch && fckMatch;
    });
    setFilteredConcretings(filter);
  }

  function onFilterClean() {
    updateSearchInputs("tower", "");
    updateSearchInputs("level", "");
    updateSearchInputs("initial_date", "");
    updateSearchInputs("final_date", "");
    updateSearchInputs("fck", "");
    setFilteredConcretings(concretingsData);
    setExpandedIndex(null);
  }

  function onConcretingAction(action) {
    const options = {
      register: async () => {
        if (
          concretingInputs["date"] === "" ||
          concretingInputs["level"] === "" ||
          concretingInputs["fck"] === ""
        ) {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          concretingsData.some(
            (concreting) =>
              concreting["tower"] === concretingInputs["tower"] &&
              concreting["level"] === concretingInputs["level"] &&
              concreting["description"] === concretingInputs["description"]
          )
        ) {
          toast({
            title: "Já existe uma concretagem cadastrada nesse local",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando concretagem...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });
          console.log(concretingInputs);
          try {
            await concretingRegister([
              userInputs,
              workInputs,
              concretingInputs,
            ]);
            // Optimistic Update
            const updatedConcretingsData = [
              ...concretingsData,
              {
                date: concretingInputs["date"],
                tower: concretingInputs["tower"],
                level: concretingInputs["level"],
                description: concretingInputs["description"],
                fck: concretingInputs["fck"],
                trucks: [],
              },
            ];
            setConcretingsData(updatedConcretingsData);
            setFilteredConcretings(updatedConcretingsData);
            onFilterUpdate(updatedConcretingsData);
            setConcretingInputs({
              date: "",
              tower: "",
              level: "",
              description: "",
              fck: "",
              tower_selected: "",
              level_selected: "",
              description_selected: "",
            });
            setFormConcreting("");
            toast({
              title: "Concretagem cadastrada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title:
                "Erro ao cadastrar a concretagem, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      edit: async () => {
        if (
          concretingInputs["date"] === "" ||
          concretingInputs["level"] === "" ||
          concretingInputs["fck"] === ""
        ) {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          (concretingInputs["tower"] !== concretingInputs["tower_selected"] ||
            concretingInputs["level"] !== concretingInputs["level_selected"] ||
            concretingInputs["description"] !==
              concretingInputs["description_selected"]) &&
          concretingsData.some(
            (concreting) =>
              concreting["tower"] === concretingInputs["tower"] &&
              concreting["level"] === concretingInputs["level"] &&
              concreting["description"] === concretingInputs["description"]
          )
        ) {
          toast({
            title: "Já existe uma concretagem cadastrada nesse local",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando concretagem...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await concretingEdit([userInputs, workInputs, concretingInputs]);
            // Optimistic Update
            const updatedConcretingsData = [...concretingsData];
            const index = updatedConcretingsData.findIndex(
              (concreting) =>
                concreting["tower"] === concretingInputs["tower_selected"] &&
                concreting["level"] === concretingInputs["level_selected"] &&
                concreting["description"] ===
                  concretingInputs["description_selected"]
            );
            const trucks = updatedConcretingsData[index]["trucks"];
            updatedConcretingsData[index] = {
              date: concretingInputs["date"],
              tower: concretingInputs["tower"],
              level: concretingInputs["level"],
              description: concretingInputs["description"],
              fck: concretingInputs["fck"],
              trucks: trucks,
            };
            setConcretingsData(updatedConcretingsData);
            setFilteredConcretings(updatedConcretingsData);
            onFilterUpdate(updatedConcretingsData);
            setConcretingInputs({
              date: "",
              tower: "",
              level: "",
              description: "",
              fck: "",
              tower_selected: "",
              level_selected: "",
              description_selected: "",
            });
            setFormConcreting("");
            toast({
              title: "Concretagem editada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar a concretagem, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      remove: async () => {
        const toastId = toast({
          title: "Removendo concretagem...",
          description: "Aguarde enquanto os dados são processados.",
          status: "loading",
          duration: null,
        });

        try {
          await concretingRemove([userInputs, workInputs, concretingInputs]);
          // Optimistic Update
          const updatedConcretingsData = [...concretingsData];
          const index = updatedConcretingsData.findIndex(
            (concreting) =>
              concreting["tower"] === concretingInputs["tower_selected"] &&
              concreting["level"] === concretingInputs["level_selected"] &&
              concreting["description"] ===
                concretingInputs["description_selected"]
          );
          updatedConcretingsData.splice(index, 1);
          setConcretingsData(updatedConcretingsData);
          setFilteredConcretings(updatedConcretingsData);
          onFilterUpdate(updatedConcretingsData);
          setConcretingInputs({
            date: "",
            tower: "",
            level: "",
            description: "",
            fck: "",
            tower_selected: "",
            level_selected: "",
            description_selected: "",
          });
          setFormConcreting("");
          toast({
            title: "Concretagem removida com sucesso",
            status: "success",
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Erro ao remover a concretagem, tente novamente mais tarde",
            status: "error",
            isClosable: true,
          });
        } finally {
          toast.close(toastId);
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  function onTruckAction(action) {
    const index = concretingsData.findIndex(
      (concreting) =>
        concreting["tower"] === concretingInputs["tower_selected"] &&
        concreting["level"] === concretingInputs["level_selected"] &&
        concreting["description"] === concretingInputs["description_selected"]
    );
    const options = {
      register: async () => {
        if (
          truckInputs["waybill"] === "" ||
          truckInputs["element"] === "" ||
          truckInputs["volume"] === ""
        ) {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          concretingsData[index]["trucks"].some(
            (info) => info["waybill"] === truckInputs["waybill"]
          )
        ) {
          toast({
            title: "Já existe um caminhão com este romaneio cadastrado",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando caminhão...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await truckRegister([
              userInputs,
              workInputs,
              concretingInputs,
              truckInputs,
            ]);
            // Optimistic Update
            const updatedConcretingsData = [...concretingsData];
            const trucks = updatedConcretingsData[index]["trucks"];
            trucks.push(truckInputs);
            updatedConcretingsData[index]["trucks"] = trucks;
            setConcretingsData(updatedConcretingsData);
            setFilteredConcretings(updatedConcretingsData);
            onFilterUpdate(updatedConcretingsData);
            updateTruckInputs("waybill", "");
            updateTruckInputs("volume", "");
            updateTruckInputs("element", "");
            updateTruckInputs("waybill_selected", "");
            setFormTruck("");
            toast({
              title: "Caminhão cadastrado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao cadastrar caminhão, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      edit: async () => {
        if (
          truckInputs["waybill"] === "" ||
          truckInputs["element"] === "" ||
          truckInputs["volume"] === ""
        ) {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          truckInputs["waybill"] !== truckInputs["waybill_selected"] &&
          concretingsData[index]["trucks"].some(
            (info) => info["waybill"] === truckInputs["waybill"]
          )
        ) {
          toast({
            title: "Já existe um caminhão com este romaneio cadastrado",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando caminhão...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await truckEdit([
              userInputs,
              workInputs,
              concretingInputs,
              truckInputs,
            ]);
            // Optimistic Update
            const updatedConcretingsData = [...concretingsData];
            const trucks = updatedConcretingsData[index]["trucks"];
            const truckIndex = trucks.findIndex(
              (truck) => truck["waybill"] === truckInputs["waybill_selected"]
            );
            const testBodies =
              updatedConcretingsData[index]["trucks"][truckIndex][
                "test_bodies"
              ];
            updatedConcretingsData[index]["trucks"][truckIndex] = {
              waybill: truckInputs["waybill"],
              volume: truckInputs["volume"],
              element: truckInputs["element"],
              test_bodies: testBodies,
            };
            setConcretingsData(updatedConcretingsData);
            setFilteredConcretings(updatedConcretingsData);
            onFilterUpdate(updatedConcretingsData);
            updateTruckInputs("waybill", "");
            updateTruckInputs("volume", "");
            updateTruckInputs("element", "");
            updateTruckInputs("waybill_selected", "");
            setFormTruck("");
            toast({
              title: "Caminhão editado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar caminhão, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      remove: async () => {
        const toastId = toast({
          title: "Removendo caminhão...",
          description: "Aguarde enquanto os dados são processados.",
          status: "loading",
          duration: null,
        });

        try {
          const updatedConcretingInputs = { ...concretingInputs };
          const updatedTruckInputs = { ...truckInputs };
          await truckRemove([
            userInputs,
            workInputs,
            updatedConcretingInputs,
            updatedTruckInputs,
          ]);
          // Optimistic Update
          const updatedConcretingsData = [...concretingsData];
          const truckIndex = updatedConcretingsData[index]["trucks"].findIndex(
            (truck) =>
              truck["waybill"] === updatedTruckInputs["waybill_selected"]
          );
          updatedConcretingsData[index]["trucks"].splice(truckIndex, 1);
          setConcretingsData(updatedConcretingsData);
          setFilteredConcretings(updatedConcretingsData);
          onFilterUpdate(updatedConcretingsData);
          updateTruckInputs("waybill", "");
          updateTruckInputs("volume", "");
          updateTruckInputs("element", "");
          updateTruckInputs("waybill_selected", "");
          setFormTruck("");
          toast({
            title: "Caminhão removido com sucesso",
            status: "success",
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Erro ao remover caminhão, tente novamente mais tarde",
            status: "error",
            isClosable: true,
          });
        } finally {
          toast.close(toastId);
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  function onTestBodyChange() {
    const concretingIndex = concretingsData.findIndex(
      (concreting) =>
        concreting["level"] === concretingInputs["level_selected"] &&
        concreting["description"] === concretingInputs["description_selected"]
    );
    truckEdit([userInputs, workInputs, concretingInputs, truckInputs])
      .then(() => {
        // Atualiza o estado somente após a resposta bem-sucedida
        const updatedConcretingsData = [...concretingsData];
        const trucks = updatedConcretingsData[concretingIndex]["trucks"];
        const truckIndex = trucks.findIndex(
          (truck) => truck["waybill"] === truckInputs["waybill_selected"]
        );
        updatedConcretingsData[concretingIndex]["trucks"][truckIndex][
          "test_bodies"
        ] = truckInputs["test_bodies"];
        setConcretingsData(updatedConcretingsData);
        setFilteredConcretings(updatedConcretingsData);
        onFilterUpdate(updatedConcretingsData);
        updateTruckInputs("waybill", "");
        updateTruckInputs("volume", "");
        updateTruckInputs("element", "");
        updateTruckInputs("waybill_selected", "");
        worksData.map((work, i) => {
          if (workInputs["selected_name"] === work["name"]) {
            const testBodies = Array.from(
              { length: work["test_bodies"].length },
              () => "-"
            );
            updateTruckInputs("test_bodies", testBodies);
          }
        });
        toast({
          title: "Corpo de prova salvo com sucesso",
          status: "success",
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao salvar corpo de prova, tente novamente mais tarde",
          status: "error",
          isClosable: true,
        });
      });
  }

  useEffect(() => {
    const fetchWorksData = async () => {
      const toastId = toast({
        title: "Carregando concretagens...",
        status: "loading",
        duration: null,
      });

      try {
        const selectedWork = worksData.find(
          (workData) => workInputs["selected_name"] === workData["name"]
        );

        if (selectedWork) {
          setWorkTestBodies(selectedWork["test_bodies"]);
          setWorkLevels(selectedWork["levels"]);
          console.log(selectedWork["towers"]);
          setWorkTowers(selectedWork["towers"]);
          if (workTowers.length !== 0) {
            updateConcretingInputs("tower", selectedWork["towers"][0]);
          }
          updateConcretingInputs("level", selectedWork["levels"][0]);
          const testBodies = Array.from(
            { length: selectedWork["test_bodies"].length },
            () => "-"
          );
          updateTruckInputs("test_bodies", testBodies);

          const concretingList = await api.post("/concreting", [
            userInputs,
            workInputs,
          ]);
          const concretings = [...concretingList["data"]];

          const truckList = await api.post("/truck", [userInputs, workInputs]);
          const trucks = [...truckList["data"]];

          concretings.map((concreting) => {
            trucks.map((truck) => {
              if (
                truck["level"] === concreting["level"] &&
                truck["description"] === concreting["description"]
              ) {
                concreting["trucks"].push(truck["truck"]);
              }
            });
          });

          setConcretingsData(concretings);
          setFilteredConcretings(concretings);
        }
      } catch (error) {
        toast({
          title: "Erro ao carregar concretagens",
          description: "Por favor, tente novamente mais tarde.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        toast.close(toastId);
      }
    };

    fetchWorksData();
  }, []);

  return (
    <>
      <div className={styles.mainEntry}>
        <div className={styles.header}>
          <Button
            variant="solid"
            onClick={() => {
              worksData.map((work, i) => {
                if (workInputs["selected_name"] === work["name"]) {
                  if (workTowers.length !== 0) {
                    updateConcretingInputs("tower", work["towers"][0]);
                  }
                  updateConcretingInputs("level", work["levels"][0]);
                }
              });

              setFormConcreting("register");
            }}
          >
            Adicionar Concretagem
          </Button>
          <div className={styles.search}>
            <InputGroup>
              <InputLeftAddon children={<SearchIcon />} />
              <Input
                type="text"
                placeholder="Buscar local"
                value={searchInputs["search"]}
                onChange={(ev) => {
                  updateSearchInputs("search", ev.target.value);
                  onSearchTermChange(ev.target.value);
                }}
              />
            </InputGroup>
            <Popover isOpen={isOpen} onClose={onClose}>
              <PopoverTrigger>
                <IconButton
                  variant="icon"
                  icon={
                    <>
                      <FaFilter
                        color={
                          !searchInputs.level &&
                          !searchInputs.initial_date &&
                          !searchInputs.final_date &&
                          !searchInputs.fck
                            ? "var(--primary-text-color)"
                            : "var(--primary-color)"
                        }
                      />
                      <Text
                        color={
                          !searchInputs.level &&
                          !searchInputs.initial_date &&
                          !searchInputs.final_date &&
                          !searchInputs.fck
                            ? "var(--primary-text-color)"
                            : "var(--primary-color)"
                        }
                      >
                        {
                          Object.entries(searchInputs).filter(
                            ([key, value]) => key !== "search" && value !== ""
                          ).length // Ignorar "search" e considerar apenas preenchidos
                        }
                      </Text>
                    </>
                  }
                  onClick={onOpen}
                />
              </PopoverTrigger>
              <PopoverContent w="400px">
                <PopoverCloseButton />
                <PopoverHeader>Filtros:</PopoverHeader>
                <PopoverBody>
                  {workTowers.length !== 0 && (
                    <FormControl>
                      <FormLabel>Torre:</FormLabel>
                      <Select
                        value={searchInputs["tower"]}
                        onChange={(ev) =>
                          onFilterAction("tower", ev.target.value)
                        }
                      >
                        <option value="">Todas</option>
                        {[...new Set(workTowers.map((tower) => tower))]
                          .sort((a, b) => a - b)
                          .map((tower, index) => (
                            <option key={index} value={tower}>
                              {tower}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl>
                    <FormLabel>Pavimento:</FormLabel>
                    <Select
                      value={searchInputs["level"]}
                      onChange={(ev) =>
                        onFilterAction("level", ev.target.value)
                      }
                    >
                      <option value="">Todos</option>
                      {[...new Set(workLevels.map((level) => level))]
                        .sort((a, b) => a - b)
                        .map((level, index) => (
                          <option key={index} value={level}>
                            {level}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <Box display="flex" flexDirection="row">
                    <FormControl>
                      <FormLabel>Início:</FormLabel>
                      <Input
                        type="date"
                        value={searchInputs["initial_date"]}
                        onChange={(ev) =>
                          onFilterAction("initial_date", ev.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Fim:</FormLabel>
                      <Input
                        type="date"
                        value={searchInputs["final_date"]}
                        onChange={(ev) =>
                          onFilterAction("final_date", ev.target.value)
                        }
                      />
                    </FormControl>
                  </Box>
                  <FormControl>
                    <FormLabel>Fck:</FormLabel>
                    <Select
                      value={searchInputs["fck"]}
                      onChange={(ev) => onFilterAction("fck", ev.target.value)}
                    >
                      <option value="">Todos</option>
                      {[...new Set(concretingsData.map((info) => info.fck))]
                        .sort((a, b) => a - b)
                        .map((fck, index) => (
                          <option key={index} value={fck}>
                            {fck}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </PopoverBody>
                <PopoverFooter>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      onClick={() => {
                        onFilterClean();
                        onClose();
                      }}
                    >
                      Limpar
                    </Button>
                  </Box>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <Table variant="colorful">
          <Thead>
            <Tr key="header">
              <Th>Data</Th>
              <Th>Local</Th>
              <Th>Volume</Th>
              <Th>Fck</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredConcretings &&
              filteredConcretings.length > 0 &&
              filteredConcretings.map((concreting, index) => (
                <>
                  <Tr
                    key={index}
                    aria-selected={(index + 1) % 2 !== 0 ? "true" : "false"}
                  >
                    <Td>{formatDate(concreting["date"])}</Td>
                    <Td>
                      {workTowers.length !== 0
                        ? concreting["description"]
                          ? concreting["tower"] +
                            " - " +
                            concreting["level"] +
                            " - " +
                            concreting["description"]
                          : concreting["tower"] + " - " + concreting["level"]
                        : concreting["description"]
                          ? concreting["level"] +
                            " - " +
                            concreting["description"]
                          : concreting["level"]}
                    </Td>
                    <Td>
                      {/* INSERIR FÓRMULA CORRETA */}
                      {concreting["trucks"].reduce(
                        (acc, truck) => acc + Number(truck["volume"]),
                        0
                      )}{" "}
                      m³
                    </Td>
                    <Td>{concreting["fck"]} MPa</Td>
                    <Td>
                      <IconButton
                        variant="icon"
                        icon={
                          expandedIndex === index ? (
                            <ChevronUpIcon />
                          ) : (
                            <ChevronDownIcon />
                          )
                        }
                        onClick={() => toggleRow(index)}
                      />
                      <IconButton
                        variant="icon"
                        icon={<EditIcon />}
                        onClick={() => {
                          setConcretingInputs({
                            date: concreting["date"],
                            tower: concreting["tower"],
                            level: concreting["level"],
                            description: concreting["description"],
                            fck: concreting["fck"],
                            tower_selected: concreting["tower"],
                            level_selected: concreting["level"],
                            description_selected: concreting["description"],
                          });
                          setFormConcreting("edit");
                        }}
                      />
                      <IconButton
                        variant="icon"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          updateConcretingInputs(
                            "tower_selected",
                            concreting["tower"]
                          );
                          updateConcretingInputs(
                            "level_selected",
                            concreting["level"]
                          );
                          updateConcretingInputs(
                            "description_selected",
                            concreting["description"]
                          );
                          setFormConcreting("remove");
                        }}
                      />
                    </Td>
                  </Tr>
                  {expandedIndex === index && (
                    <Tr
                      key={index + "expanded"}
                      aria-selected={(index + 1) % 2 !== 0 ? "true" : "false"}
                    >
                      <Table variant="subtable">
                        <Thead>
                          <Tr
                            aria-selected={
                              (index + 1) % 2 !== 0 ? "true" : "false"
                            }
                            key={index + "subtable"}
                          >
                            <Th>Romaneio</Th>
                            <Th>Elemento concretado</Th>
                            <Th>Volume</Th>
                            <div className={styles.thDiv}>
                              <div className={styles.thHeader}>
                                <th>Corpos de Prova</th>
                              </div>
                              <div className={styles.thHeader}>
                                {workTestBodies.map((day, dayIndex) => (
                                  <th>{day}d</th>
                                ))}
                              </div>
                            </div>
                            <Th></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {concreting["trucks"] &&
                            concreting["trucks"].length > 0 &&
                            concreting["trucks"].map((truck, detailIndex) => (
                              <Tr
                                key={detailIndex}
                                aria-selected={
                                  (index + 1) % 2 !== 0 ? "true" : "false"
                                }
                              >
                                <Td>{truck["waybill"]}</Td>
                                <Td>{truck["element"]}</Td>
                                <Td>{truck["volume"]} m³</Td>
                                <div className={styles.thHeader}>
                                  {truck["test_bodies"].map(
                                    (testBody, testBodyIndex) => (
                                      <Editable
                                        defaultValue={testBody}
                                        w="100%"
                                        variant={
                                          testBody === "-"
                                            ? "normal"
                                            : Number(testBody) >=
                                                Math.exp(
                                                  0.25 *
                                                    (1 -
                                                      Math.pow(
                                                        28 /
                                                          workTestBodies[
                                                            testBodyIndex
                                                          ],
                                                        0.5
                                                      ))
                                                ) *
                                                  concreting["fck"]
                                              ? "green"
                                              : "red"
                                        }
                                        onSubmit={onTestBodyChange}
                                      >
                                        <EditablePreview />
                                        <EditableInput
                                          onKeyPress={(event) => {
                                            if (!/[0-9.-]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(ev) => {
                                            const updatedTestBodies =
                                              truck["test_bodies"];
                                            updatedTestBodies[testBodyIndex] =
                                              ev.target.value;
                                            updateConcretingInputs(
                                              "tower_selected",
                                              concreting["tower"]
                                            );
                                            updateConcretingInputs(
                                              "level_selected",
                                              concreting["level"]
                                            );
                                            updateConcretingInputs(
                                              "description_selected",
                                              concreting["description"]
                                            );
                                            updateTruckInputs(
                                              "waybill",
                                              truck["waybill"]
                                            );
                                            updateTruckInputs(
                                              "element",
                                              truck["element"]
                                            );
                                            updateTruckInputs(
                                              "volume",
                                              truck["volume"]
                                            );
                                            updateTruckInputs(
                                              "test_bodies",
                                              updatedTestBodies
                                            );
                                            updateTruckInputs(
                                              "waybill_selected",
                                              truck["waybill"]
                                            );
                                          }}
                                        />
                                      </Editable>
                                    )
                                  )}
                                </div>
                                <Td>
                                  <IconButton
                                    variant="icon"
                                    icon={<EditIcon />}
                                    onClick={() => {
                                      updateConcretingInputs(
                                        "tower_selected",
                                        concreting["tower"]
                                      );
                                      updateConcretingInputs(
                                        "level_selected",
                                        concreting["level"]
                                      );
                                      updateConcretingInputs(
                                        "description_selected",
                                        concreting["description"]
                                      );
                                      updateTruckInputs(
                                        "waybill",
                                        truck["waybill"]
                                      );
                                      updateTruckInputs(
                                        "element",
                                        truck["element"]
                                      );
                                      updateTruckInputs(
                                        "volume",
                                        truck["volume"]
                                      );
                                      updateTruckInputs(
                                        "waybill_selected",
                                        truck["waybill"]
                                      );
                                      setFormTruck("edit");
                                    }}
                                    size="sm"
                                  />
                                  <IconButton
                                    variant="icon"
                                    icon={<DeleteIcon />}
                                    onClick={() => {
                                      updateConcretingInputs(
                                        "tower_selected",
                                        concreting["tower"]
                                      );
                                      updateConcretingInputs(
                                        "level_selected",
                                        concreting["level"]
                                      );
                                      updateConcretingInputs(
                                        "description_selected",
                                        concreting["description"]
                                      );
                                      updateTruckInputs(
                                        "waybill_selected",
                                        truck["waybill"]
                                      );
                                      setFormTruck("remove");
                                    }}
                                    size="sm"
                                  />
                                </Td>
                              </Tr>
                            ))}
                          <Tr
                            aria-selected={
                              (index + 1) % 2 !== 0 ? "true" : "false"
                            }
                            key="add-truck-row"
                          >
                            <IconButton
                              variant="icon"
                              icon={<AddIcon />}
                              onClick={() => {
                                updateConcretingInputs(
                                  "tower_selected",
                                  concreting["tower"]
                                );
                                updateConcretingInputs(
                                  "level_selected",
                                  concreting["level"]
                                );
                                updateConcretingInputs(
                                  "description_selected",
                                  concreting["description"]
                                );
                                setFormTruck("register");
                              }}
                              size="sm"
                            />
                          </Tr>
                        </Tbody>
                      </Table>
                    </Tr>
                  )}
                </>
              ))}
          </Tbody>
        </Table>
        {/* AS COLUNAS DE CADA ROMANEIO TERÁ QUE SER CUSTOMIZÁVEL O USUÁRIO VAI CONFIGURAR QUANTOS CORPOS DE PROVA E A QUAL DIA SE REFERE CADA UM */}
      </div>
      {(formConcreting === "register" || formConcreting === "edit") && (
        <Modal isCentered isOpen={formConcreting !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cadastro</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormConcreting("");
                setConcretingInputs({
                  date: "",
                  tower: "",
                  level: "",
                  description: "",
                  fck: "",
                  tower_selected: "",
                  level_selected: "",
                  description_selected: "",
                });
              }}
            />
            <ModalBody>
              <FormControl>
                <FormLabel>Data:</FormLabel>
                <Input
                  type="date"
                  value={concretingInputs["date"]}
                  onChange={(ev) =>
                    updateConcretingInputs("date", ev.target.value)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Local:</FormLabel>
                <FormControl display="flex" flexDirection="row">
                  {workTowers.length !== 0 && (
                    <Select
                      value={concretingInputs["tower"]}
                      onChange={(ev) =>
                        updateConcretingInputs("tower", ev.target.value)
                      }
                    >
                      {[...new Set(workTowers.map((tower) => tower))]
                        .sort((a, b) => a - b)
                        .map((tower, index) => (
                          <option key={index} value={tower}>
                            {tower}
                          </option>
                        ))}
                    </Select>
                  )}
                  <Select
                    value={concretingInputs["level"]}
                    onChange={(ev) =>
                      updateConcretingInputs("level", ev.target.value)
                    }
                  >
                    {[...new Set(workLevels.map((level) => level))]
                      .sort((a, b) => a - b)
                      .map((level, index) => (
                        <option key={index} value={level}>
                          {level}
                        </option>
                      ))}
                  </Select>
                  <Input
                    placeholder="Descrição"
                    type="text"
                    value={concretingInputs["description"]}
                    onChange={(ev) =>
                      updateConcretingInputs("description", ev.target.value)
                    }
                  />
                </FormControl>
              </FormControl>
              <FormControl>
                <FormLabel>Fck:</FormLabel>
                <Input
                  type="number"
                  value={concretingInputs["fck"]}
                  onChange={(ev) =>
                    updateConcretingInputs("fck", ev.target.value)
                  }
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => onConcretingAction(formConcreting)}>
                SALVAR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {formConcreting === "remove" && (
        <Modal isCentered isOpen={formConcreting !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Excluir a concretagem:{" "}
              <strong>
                {concretingInputs["description_selected"]
                  ? concretingInputs["level_selected"] +
                    " - " +
                    concretingInputs["description_selected"]
                  : concretingInputs["level_selected"]}
              </strong>
            </ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormConcreting("");
                updateConcretingInputs({
                  tower_selected: "",
                });
                updateConcretingInputs({
                  level_selected: "",
                });
                updateConcretingInputs({
                  description_selected: "",
                });
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button
                variant="delete"
                onClick={() => onConcretingAction(formConcreting)}
              >
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {(formTruck === "register" || formTruck === "edit") && (
        <Modal isCentered isOpen={formTruck !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cadastro</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormTruck("");
                updateTruckInputs("waybill", "");
                updateTruckInputs("volume", "");
                updateTruckInputs("element", "");
                updateTruckInputs("waybill_selected", "");
              }}
            />
            <ModalBody>
              <FormControl>
                <FormLabel>Romaneio:</FormLabel>
                <Input
                  type="number"
                  value={truckInputs["waybill"]}
                  onChange={(ev) =>
                    updateTruckInputs("waybill", ev.target.value)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Elemento concretado:</FormLabel>
                <Input
                  type="string"
                  value={truckInputs["element"]}
                  onChange={(ev) =>
                    updateTruckInputs("element", ev.target.value)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Volume:</FormLabel>
                <Input
                  type="number"
                  value={truckInputs["volume"]}
                  onChange={(ev) =>
                    updateTruckInputs("volume", ev.target.value)
                  }
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => onTruckAction(formTruck)}>SALVAR</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {formTruck === "remove" && (
        <Modal isCentered isOpen={formTruck !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Excluir o romaneio:{" "}
              <strong>{truckInputs["waybill_selected"]}</strong>
            </ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormTruck("");
                updateConcretingInputs({
                  tower_selected: "",
                });
                updateConcretingInputs({
                  level_selected: "",
                });
                updateConcretingInputs({
                  description_selected: "",
                });
                setTruckInputs({
                  waybill_selected: "",
                });
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button variant="delete" onClick={() => onTruckAction(formTruck)}>
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Home;
