import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  Tab,
  TabList,
  Table,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { IconButton, Input, Tooltip } from "@chakra-ui/react";
import {
  CheckIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { FaRegSave } from "react-icons/fa";

import styles from "../Main.module.css";

import parametersMethods from "../utils/data/parametersMethods.json";

import {
  methodDuplicate,
  methodEdit,
  methodRemove,
  methodSave,
} from "../utils/services/methods";

function Config({ userInputs, methodsData, setMethodsData }) {
  const toast = useToast();
  const [methodInputs, setMethodInputs] = useState({
    foundation_class: "estacas",
    selected_name: "Aoki-Velloso",
    name_input: "",
    parameters: parametersMethods["estacas"]["Aoki-Velloso"],
  });
  const [formOpen, setFormOpen] = useState("");

  function updateMethodInputs(name, value) {
    setMethodInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onMethodFoundationClassChange(ev) {
    updateMethodInputs("foundation_class", Object.keys(parametersMethods)[ev]);
    if (Object.keys(parametersMethods)[ev] === "estacas") {
      updateMethodInputs("selected_name", "Aoki-Velloso");
      updateMethodInputs(
        "parameters",
        parametersMethods["estacas"]["Aoki-Velloso"]
      );
    } else if (Object.keys(parametersMethods)[ev] === "sapatas") {
      updateMethodInputs("selected_name", "Bulbo de Tensões");
      updateMethodInputs(
        "parameters",
        parametersMethods["sapatas"]["Bulbo de Tensões"]
      );
    }
  }

  function onMethodSelectedChange(ev) {
    const value = ev.target.value;
    updateMethodInputs("selected_name", value);
    if (value === "Aoki-Velloso" || value === "Decourt-Quaresma") {
      updateMethodInputs("parameters", parametersMethods["estacas"][value]);
    } else if (value === "Bulbo de Tensões") {
      updateMethodInputs("parameters", parametersMethods["sapatas"][value]);
    } else {
      updateMethodInputs(
        "parameters",
        methodsData[methodInputs["foundation_class"]][value]
      );
    }
  }

  function onMethodInputChange(ev) {
    const value = ev.target.value;
    updateMethodInputs("name_input", value);
  }

  function onMethodActions(action) {
    const options = {
      duplicate: () => {
        if (methodInputs["name_input"] === "") {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          Object.keys({ ...methodsData.estacas, ...methodsData.sapatas }).some(
            (name) => name === methodInputs.name_input
          )
        ) {
          toast({
            title:
              "Um método com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          methodDuplicate([userInputs, methodInputs])
            .then(() => {
              // Optimistic update
              const updatedMethodsData = { ...methodsData };
              if (methodInputs["foundation_class"] === "estacas") {
                updatedMethodsData["estacas"][methodInputs["name_input"]] =
                  methodInputs["parameters"];
              } else {
                updatedMethodsData["sapatas"][methodInputs["name_input"]] =
                  methodInputs["parameters"];
              }
              setMethodsData(updatedMethodsData);
              toast({
                title: "Método criado com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: "Erro ao criar o método, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
          updateMethodInputs("selected_name", methodInputs["name_input"]);
          setFormOpen("");
          updateMethodInputs("name_input", "");
        }
      },
      edit: () => {
        if (methodInputs["name_input"] === "") {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          Object.keys({ ...methodsData.estacas, ...methodsData.sapatas }).some(
            (name) => name === methodInputs.name_input
          )
        ) {
          toast({
            title:
              "Um método com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          methodEdit([userInputs, methodInputs])
            .then(() => {
              // Optimistic update
              const updatedMethodsData = { ...methodsData };
              if (methodInputs["foundation_class"] === "estacas") {
                updatedMethodsData["estacas"][methodInputs["name_input"]] =
                  methodInputs["parameters"];
                delete updatedMethodsData["estacas"][
                  methodInputs["selected_name"]
                ];
              } else {
                updatedMethodsData["sapatas"][methodInputs["name_input"]] =
                  methodInputs["parameters"];
                delete updatedMethodsData["sapatas"][
                  methodInputs["selected_name"]
                ];
              }
              setMethodsData(updatedMethodsData);
              toast({
                title: "Método editado com sucesso",
                status: "success",
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: "Erro ao editar o método, tente novamente mais tarde",
                status: "error",
                isClosable: true,
              });
            });
          updateMethodInputs("selected_name", methodInputs["name_input"]);
          setFormOpen("");
          updateMethodInputs("name_input", "");
        }
      },
      remove: () => {
        methodRemove([userInputs, methodInputs])
          .then(() => {
            // Optimistic update
            const updatedMethodsData = { ...methodsData };
            if (methodInputs["foundation_class"] === "estacas") {
              delete updatedMethodsData["estacas"][
                methodInputs["selected_name"]
              ];
            } else {
              delete updatedMethodsData["sapatas"][
                methodInputs["selected_name"]
              ];
            }
            setMethodsData(updatedMethodsData);
            toast({
              title: "Método removido com sucesso",
              status: "success",
              isClosable: true,
            });
          })
          .catch(() => {
            toast({
              title: "Erro ao remover o método, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          });
        setFormOpen("");
        updateMethodInputs(
          "selected_name",
          Object.keys(parametersMethods[methodInputs["foundation_class"]])[0]
        );
        updateMethodInputs(
          "parameters",
          parametersMethods[methodInputs["foundation_class"]][
            Object.keys(parametersMethods[methodInputs["foundation_class"]])[0]
          ]
        );
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  function onParameterInputChange(key, row, col, value) {
    setMethodInputs((prevInputs) => {
      const newState = { ...prevInputs };
      newState["parameters"][key][row][col] = parseFloat(value);
      return newState;
    });
    methodSave([userInputs, methodInputs])
      .then(() => {
        toast({
          title: "Parâmetro editado com sucesso",
          status: "success",
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao editar o parâmetro, tente novamente mais tarde",
          status: "error",
          isClosable: true,
        });
      });
  }

  return (
    <>
      <div className={styles.mainConfig}>
        <div className={styles.configMenu}>
          <Tabs
            onChange={onMethodFoundationClassChange}
            variant="soft-rounded"
            color="var(--background-dark-color)"
            textColor="var(--text-color)"
            value={methodInputs["foundation_class"]}
          >
            <TabList>
              <Tab checked={methodInputs["foundation_class"] === "estacas"}>
                Métodos de Cálculo Estacas
              </Tab>
              <Tab checked={methodInputs["foundation_class"] === "sapatas"}>
                Métodos de Cálculo Sapatas
              </Tab>
            </TabList>
          </Tabs>
        </div>
        <div className={styles.configMenu}>
          <Select
            onChange={onMethodSelectedChange}
            variant="outline"
            w="200px"
            value={methodInputs["selected_name"]}
          >
            {Object.keys(methodsData[methodInputs["foundation_class"]]).map(
              (method) => (
                <option key={method}>{method}</option>
              )
            )}
          </Select>
          <Popover
            isOpen={formOpen === "duplicate"}
            onOpen={() => setFormOpen("duplicate")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                variant="icon"
                icon={
                  <Tooltip
                    hasArrow
                    label="Duplicar"
                    bg="gray"
                    color="black"
                    fontSize="md"
                  >
                    <CopyIcon />
                  </Tooltip>
                }
                isDisabled={
                  methodInputs["selected_name"] !== "Aoki-Velloso" &&
                  methodInputs["selected_name"] !== "Decourt-Quaresma" &&
                  methodInputs["selected_name"] !== "Bulbo de Tensões"
                }
              />
            </PopoverTrigger>
            <PopoverContent flexDirection={"row"}>
              <PopoverArrow backgroundColor={"black"} />
              <Input
                type="text"
                placeholder="Digite o nome do método"
                onChange={onMethodInputChange}
                value={methodInputs["name_input"]}
              />
              <IconButton
                variant="icon"
                icon={<CheckIcon />}
                onClick={() => onMethodActions("duplicate")}
              />
              <IconButton
                variant="icon"
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  setMethodInputs((prevInputs) => ({
                    ...prevInputs,
                    ["name_input"]: "",
                  }));
                }}
              />
            </PopoverContent>
          </Popover>
          <Popover
            isOpen={formOpen === "edit"}
            onOpen={() => setFormOpen("edit")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                variant="icon"
                icon={
                  <Tooltip
                    hasArrow
                    label="Renomear"
                    bg="gray"
                    color="black"
                    fontSize="md"
                  >
                    <EditIcon />
                  </Tooltip>
                }
                isDisabled={
                  methodInputs["selected_name"] === "Aoki-Velloso" ||
                  methodInputs["selected_name"] === "Decourt-Quaresma" ||
                  methodInputs["selected_name"] === "Bulbo de Tensões"
                }
              />
            </PopoverTrigger>
            <PopoverContent flexDirection={"row"}>
              <PopoverArrow backgroundColor={"black"} />
              <Input
                type="text"
                placeholder="Digite o nome do método"
                onChange={onMethodInputChange}
                value={methodInputs["name_input"]}
              />
              <IconButton
                variant="icon"
                icon={<CheckIcon />}
                onClick={() => onMethodActions("edit")}
              />
              <IconButton
                variant="icon"
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  setMethodInputs((prevInputs) => ({
                    ...prevInputs,
                    ["name_input"]: "",
                  }));
                }}
              />
            </PopoverContent>
          </Popover>
          <IconButton
            variant="icon"
            icon={
              <Tooltip
                hasArrow
                label="Remover"
                bg="gray"
                color="black"
                fontSize="md"
              >
                <DeleteIcon />
              </Tooltip>
            }
            onClick={() => {
              setFormOpen("remove");
            }}
            isDisabled={
              methodInputs["selected_name"] === "Aoki-Velloso" ||
              methodInputs["selected_name"] === "Decourt-Quaresma" ||
              methodInputs["selected_name"] === "Bulbo de Tensões"
            }
          />
        </div>
        <div className={styles.configSection}>
          <Accordion defaultIndex={[0]} allowMultiple w="100%">
            {Object.entries(methodInputs["parameters"]).map(([key, value]) => {
              return (
                <AccordionItem>
                  {key === "TIPO DO MÉTODO" ? (
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <strong>
                          {key}: {value}
                        </strong>
                      </Box>
                    </AccordionButton>
                  ) : (
                    <>
                      <AccordionButton>{key}</AccordionButton>
                      <AccordionPanel>
                        <Table variant="simple">
                          <Thead>
                            <Tr>
                              {methodInputs["selected_name"] ===
                                "Aoki-Velloso" ||
                              methodInputs["selected_name"] ===
                                "Decourt-Quaresma" ||
                              methodInputs["selected_name"] ==
                                "Bulbo de Tensões"
                                ? Object.keys(value[0]).map((header) => (
                                    <Th key={header}>{header}</Th>
                                  ))
                                : Object.keys(
                                    parametersMethods[
                                      methodInputs["foundation_class"]
                                    ][
                                      methodInputs["parameters"][
                                        "TIPO DO MÉTODO"
                                      ]
                                    ][key][0]
                                  ).map((header) => (
                                    <Th key={header}>{header}</Th>
                                  ))}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {value.map((element, i) => (
                              <Tr key={i}>
                                {methodInputs["selected_name"] ===
                                  "Aoki-Velloso" ||
                                methodInputs["selected_name"] ===
                                  "Decourt-Quaresma" ||
                                methodInputs["selected_name"] ==
                                  "Bulbo de Tensões"
                                  ? Object.values(element).map(
                                      (content, col_index) => (
                                        <Td key={col_index}>
                                          <Box fontSize="md">{content}</Box>
                                        </Td>
                                      )
                                    )
                                  : Object.keys(
                                      parametersMethods[
                                        methodInputs["foundation_class"]
                                      ][
                                        methodInputs["parameters"][
                                          "TIPO DO MÉTODO"
                                        ]
                                      ][key][0]
                                    ).map((header, col_index) => (
                                      <Td key={col_index}>
                                        {methodInputs["foundation_class"] ===
                                          "estacas" &&
                                        methodInputs["selected_name"] !==
                                          "Aoki-Velloso" &&
                                        methodInputs["selected_name"] !==
                                          "Decourt-Quaresma" &&
                                        col_index !== 0 ? (
                                          <Editable
                                            defaultValue={
                                              element[header] || "0"
                                            }
                                            fontSize="md"
                                            onSubmit={(newValue) => {
                                              onParameterInputChange(
                                                key,
                                                i,
                                                header,
                                                newValue
                                              );
                                            }}
                                          >
                                            <EditablePreview />
                                            <EditableInput
                                              textAlign="start"
                                              onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                          </Editable>
                                        ) : methodInputs["foundation_class"] ===
                                            "sapatas" &&
                                          methodInputs["selected_name"] !==
                                            "Bulbo de Tensões" &&
                                          col_index > 1 ? (
                                          <Editable
                                            defaultValue={
                                              element[header] || "0"
                                            }
                                            fontSize="md"
                                            onSubmit={(newValue) => {
                                              onParameterInputChange(
                                                key,
                                                i,
                                                header,
                                                newValue
                                              );
                                            }}
                                          >
                                            <EditablePreview />
                                            <EditableInput
                                              textAlign="start"
                                              onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                          </Editable>
                                        ) : (
                                          <Box fontSize="md">
                                            {element[header]}
                                          </Box>
                                        )}
                                      </Td>
                                    ))}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
      {formOpen === "remove" && methodInputs["selected_name"] !== "" && (
        <Modal isCentered isOpen={formOpen !== ""} onClose={""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Excluir {methodInputs["selected_name"]}</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormOpen("");
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button
                variant="delete"
                onClick={() => onMethodActions(formOpen)}
              >
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Config;
